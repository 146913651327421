import { ELEMENTAR_BASE_URL, HUMAN_LOOP_BASE_URL } from 'apiRoutes';
import { http, handleErrors } from '../../services/http-wrapper';
import { Auth } from 'aws-amplify';

export const ApiPostLegalBase = async ({
  initial_date,
  final_date,
  cnpj,
  nat_oper,
  market,
  estab_rem,
  tributation
}) => {
  let data = {
    initial_date: initial_date,
    final_date: final_date,
    cnpj: cnpj,
    nat_oper: nat_oper,
    market: market,
    estab_rem: estab_rem,
    tributation: tributation
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${ELEMENTAR_BASE_URL}/legal_base/execution`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetNCMList = async () => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/legal_base/get/ncm_distinct`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetNCMInfo = async ({ cnpj, ncm }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/legal_base/get/ncm_result?cnpj=${cnpj}&ncm=${ncm}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

// Tax Information Page
export const ApiTaxInformation = async ({
  days,
  org,
  org_subs,
  ato,
  filtero_tributos,
  filtero_keyword
}) => {
  //1
  let isFilter = org || org_subs || ato ? true : false;
  //2
  let filtersForOrgSubsAto = {
    active: 'true',
    org: org?.length > 0 ? org : 'Todos',
    org_subs: org_subs?.length > 0 ? org_subs : 'Todos',
    ato: ato?.length > 0 ? ato : 'Todos'
  };
  //3
  let noFiltersOrgSubsAto = {
    active: 'false'
  };
  //4
  let data = {
    days: days,
    filtero_org_subs_ato: isFilter ? filtersForOrgSubsAto : noFiltersOrgSubsAto,
    filtero_tributos: filtero_tributos ? 'true' : 'false',
    filtero_keyword: filtero_keyword ? 'true' : 'false'
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${HUMAN_LOOP_BASE_URL}/dou/post`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiTaxInformationSP = async ({ days, caderno, secao }) => {
  // testa se há filtros preenchidos
  // let isFilter = caderno || secao ? true : false;
  // objeto dentro de um atributo do objeto do body
  let filtersCadernoSecao = {
    active: 'true',
    caderno: caderno?.length > 0 ? caderno : 'Todos',
    secao: secao?.length > 0 ? secao : 'Todos'
  };
  // outra opção para o objeto
  /*
  let noFiltersCadernoSecao = {
    active: 'false'
  };
  */
  // objeto do body
  let data = {
    days: days,
    filtero_doe_sp: filtersCadernoSecao
  };
  // filtero_doe_sp: isFilter ? filtersCadernoSecao : noFiltersCadernoSecao
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${HUMAN_LOOP_BASE_URL}/doe_sp/post`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};
