import { ELEMENTAR_BASE_URL } from 'apiRoutes';
import { Auth } from 'aws-amplify';
import { handleErrors, http } from 'services/http-wrapper';

//=========GET ALL SPED TYPE LIST=============//
export const ApiGetSpedtype = async () => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/sped/list/tipo`,
      user.getIdToken().getJwtToken()
    );
    // console.log(user.getIdToken().getJwtToken());
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetSped = async ({
  companyid,
  page,
  per_page,
  type_sped,
  status,
  DataInicio,
  DataFim
}) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/sped/list/by/company?company_id=${companyid}&type_sped=${type_sped}&status=${status}&datainicio=${DataInicio}&datafim=${DataFim}&page=${
        page + 1
      }&per_page=${per_page}`,
      user.getIdToken().getJwtToken(),
      { companyid },
      { type_sped },
      { status },
      { DataInicio },
      { DataFim },
      { page },
      { per_page }
    );
    // console.log(user.getIdToken().getJwtToken());
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetFiscalTable = async ({ id_sped, table, page, per_page }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/spedfiscal/list?id_sped=${id_sped}&page=${
        page + 1
      }&per_page=${per_page}&table=${table}`,
      user.getIdToken().getJwtToken(),
      { id_sped },
      { table },
      { page },
      { per_page }
    );
    // console.log(user.getIdToken().getJwtToken());
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetContribTable = async ({
  id_sped,
  table,
  page,
  per_page
}) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/spedcontrib/list?id_sped=${id_sped}&page=${
        page + 1
      }&per_page=${per_page}&table=${table}`,
      user.getIdToken().getJwtToken(),
      { id_sped },
      { table },
      { page },
      { per_page }
    );
    // console.log(user.getIdToken().getJwtToken());
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetSpedCruzaAnaliseType = async ({ type_sped, table }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/list_table/by_sped_type?type_sped=${type_sped}&table=${table}`,
      user.getIdToken().getJwtToken(),
      { type_sped },
      { table }
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};
export const ApiGetSpedAnaliseType = async ({ type_sped, table }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/list_table/by_sped_type?type_sped=${type_sped}&table=${table}`,
      user.getIdToken().getJwtToken(),
      { type_sped },
      { table }
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetSpedCruzaAnaliseContribType = async ({
  type_sped,
  table
}) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/list_table/by_sped_type?type_sped=${type_sped}&table=${table}`,
      user.getIdToken().getJwtToken(),
      { type_sped },
      { table }
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetSpedEndpoint = async ({
  endpoint,
  id_sped,
  table,
  per_page,
  page
}) => {
  try {
    let user = await Auth.currentSession();
    const url = `${ELEMENTAR_BASE_URL}${endpoint}`.split('&');
    const urlPartone = `${url[0]}${id_sped}`;
    const urlPartTwo = `${'&'}${url[1]}${table}`;
    const urlPartThree = `${'&'}${url[3]}${page + 1}`;
    const urlPartfour = `${'&'}${url[2]}${per_page}`;
    const mainUrl = urlPartone + urlPartTwo + urlPartThree + urlPartfour;
    const api = await http.get(
      mainUrl,
      user.getIdToken().getJwtToken(),
      { id_sped },
      { table },
      { per_page },
      { page }
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetSpedDownloadEndpoint = async ({
  endpoint,
  id_sped,
  table,
  per_page,
  page
}) => {
  try {
    let user = await Auth.currentSession();
    const url = `${ELEMENTAR_BASE_URL}${endpoint}`.split('&');
    const urlPartone = `${url[0]}${id_sped}`;
    const urlPartTwo = `${'&'}${url[1]}${table}`;
    const urlPartThree = `${'&'}${url[3]}${page + 1}`;
    const urlPartfour = `${'&'}${url[2]}${per_page}`;
    const mainUrl = urlPartone + urlPartTwo + urlPartThree + urlPartfour;
    const api = await http.get(
      mainUrl,
      user.getIdToken().getJwtToken(),
      { id_sped },
      { table },
      { per_page },
      { page }
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetSpedCruzaEndpoint = async ({
  endpoint,
  id_sped,
  page,
  per_page
}) => {
  try {
    let user = await Auth.currentSession();
    let url = `${ELEMENTAR_BASE_URL}${endpoint}`.split('&');
    const urlPartOne = `${url[0]}${id_sped}`;
    const urlPartTwo = `${'&'}${url[1]}${page + 1}`;
    const urlPartThree = `${'&'}${url[2]}${per_page}`;
    const mainUrl = urlPartOne + urlPartTwo + urlPartThree;
    const api = await http.get(
      mainUrl,
      user.getIdToken().getJwtToken(),
      { id_sped },
      { page },
      { per_page }
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetSpedCruzaDownloadEndpoint = async ({
  endpoint,
  id_sped,
  page,
  per_page
}) => {
  try {
    let user = await Auth.currentSession();
    let url = `${ELEMENTAR_BASE_URL}${endpoint}`.split('&');
    const urlPartOne = `${url[0]}${id_sped}`;
    const urlPartTwo = `${'&'}${url[1]}${page + 1}`;
    const urlPartThree = `${'&'}${url[2]}${per_page}`;
    const mainUrl = urlPartOne + urlPartTwo + urlPartThree;
    const api = await http.get(
      mainUrl,
      user.getIdToken().getJwtToken(),
      { id_sped },
      { page },
      { per_page }
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetSpedExecEndpoint = async ({ id_sped, endpoint }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}${endpoint}${id_sped}`,
      user.getIdToken().getJwtToken(),
      { id_sped }
    );
    // console.log(`${ELEMENTAR_BASE_URL}${endpoint}${id_sped}`);
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetSpedExecStatus = async ({ id_sped, operation }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/list_table/analise_status?id_sped=${id_sped}&operation=${operation}`,
      user.getIdToken().getJwtToken(),
      { id_sped }
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetSpedBatchExec = async ({
  company_id,
  initial_date,
  final_date
}) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/analise_sped/execution_batch?initial_date=${initial_date}&final_date=${final_date}&company_id=${company_id}`,
      user.getIdToken().getJwtToken(),
      { company_id },
      { initial_date },
      { final_date }
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetQuadroEndpoint = async ({
  endpoint,
  company_id,
  initial_date,
  final_date,
  page,
  per_page
}) => {
  try {
    let user = await Auth.currentSession();
    const url = `${ELEMENTAR_BASE_URL}${endpoint}`.split('&');
    const urlPartone = `${url[0]}${company_id}`;
    const urlPartTwo = `${'&'}${url[1]}${initial_date}`;
    const urlPartThree = `${'&'}${url[2]}${final_date}`;
    const urlPartFour = `${'&'}${url[3]}${page + 1}`;
    const urlPartFive = `${'&'}${url[4]}${per_page}`;
    const mainUrl =
      urlPartone + urlPartTwo + urlPartThree + urlPartFour + urlPartFive;
    const api = await http.get(
      mainUrl,
      user.getIdToken().getJwtToken(),
      { company_id },
      { initial_date },
      { final_date },
      { page },
      { per_page }
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetQuadroDownloadEndpoint = async ({
  endpoint,
  company_id,
  initial_date,
  final_date,
  page,
  per_page
}) => {
  try {
    let user = await Auth.currentSession();
    const url = `${ELEMENTAR_BASE_URL}${endpoint}`.split('&');
    const urlPartone = `${url[0]}${company_id}`;
    const urlPartTwo = `${'&'}${url[1]}${initial_date}`;
    const urlPartThree = `${'&'}${url[2]}${final_date}`;
    const urlPartFour = `${'&'}${url[3]}${page + 1}`;
    const urlPartFive = `${'&'}${url[4]}${per_page}`;
    const mainUrl =
      urlPartone + urlPartTwo + urlPartThree + urlPartFour + urlPartFive;
    const api = await http.get(
      mainUrl,
      user.getIdToken().getJwtToken(),
      { company_id },
      { initial_date },
      { final_date },
      { page },
      { per_page }
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetConsultaExec = async ({ company_id }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/list_table/analise_status_by_company?company_id=${company_id}`,
      user.getIdToken().getJwtToken(),
      { company_id }
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetConsultaByPhase = async ({
  company_id,
  phase,
  analisys_id
}) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/list_table/analise_status_by_phase?company_id=${company_id}&phase=${phase}&analisys_id=${analisys_id}`,
      user.getIdToken().getJwtToken(),
      { company_id },
      { phase },
      { analisys_id }
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiDeleteSped = async ({ id }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${ELEMENTAR_BASE_URL}/sped/delete/${id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

// export const ApiChangeSpedStatus = async ({ id }) => {
//   try {
//     let user = await Auth.currentSession();
//     const api = await http.get(
//       `${ELEMENTAR_BASE_URL}/sped/update/?IdProdutoFicha3=${id}`,
//       user.getIdToken().getJwtToken(),
//       { IdProdutoFicha3 }
//     );
//     return api.data;
//   } catch (error) {
//     handlerErros(error);
//   }
// };
