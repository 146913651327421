import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ApiGetCategories,
  ApiGetCategoriesFiles,
  ApiSaveCategoriesFiles,
  ApiSaveNewCategory
} from './definirCategorias';
//to change

export const getCategories = createAsyncThunk('api/getcategories', async () => {
  let response = await ApiGetCategories();
  return response;
});

export const getCategoriesFiles = createAsyncThunk(
  'api/getcategoriesfiles',
  async (category) => {
    let response = await ApiGetCategoriesFiles(category);
    return response;
  }
);

export const saveCategoriesFiles = createAsyncThunk(
  'api/savecategoriesfiles',
  async (values) => {
    let response = await ApiSaveCategoriesFiles(values);
    return response;
  }
);

export const saveNewCategory = createAsyncThunk(
  'api/savenewcategory',
  async (id) => {
    let response = await ApiSaveNewCategory(id);
    return response;
  }
);

//to change
export const definirCategoriasDataSlice = createSlice({
  name: 'definircategorias',
  initialState: {
    //getCategories:
    isLoadingCategories: false,
    categories: [],
    //getCategoriesFiles:
    isLoadingCategoriesFiles: false,
    categoriesFiles: [],
    //saveCategoriesFiles:
    isSavingCategoriesFiles: false,
    savedCategoriesFiles: false,
    //saveCategoriesFiles:
    isSavingNewCategory: false,
    newCategory: false
  },
  reducers: {},
  extraReducers: {
    //getCategories:
    [getCategories.pending]: (state) => {
      state.isLoadingCategories = 'loading';
    },
    [getCategories.fulfilled]: (state, action) => {
      state.isLoadingCategories = 'success';
      state.categories = action.payload;
    },
    [getCategories.rejected]: (state) => {
      state.isLoadingCategories = 'failed';
    },
    //getCategoriesFiles:
    [getCategoriesFiles.pending]: (state) => {
      state.isLoadingCategoriesFiles = 'loading';
    },
    [getCategoriesFiles.fulfilled]: (state, action) => {
      state.isLoadingCategoriesFiles = 'success';
      state.categoriesFiles = action.payload;
    },
    [getCategoriesFiles.rejected]: (state) => {
      state.isLoadingCategoriesFiles = 'failed';
    },
    //saveCategoriesFiles:
    [saveCategoriesFiles.pending]: (state) => {
      state.isSavingCategoriesFiles = 'loading';
    },
    [saveCategoriesFiles.fulfilled]: (state, action) => {
      state.isSavingCategoriesFiles = 'success';
      state.savedCategoriesFiles = action.payload;
    },
    [saveCategoriesFiles.rejected]: (state) => {
      state.isSavingCategoriesFiles = 'failed';
    },
    //saveNewCategory:
    [saveNewCategory.pending]: (state) => {
      state.isSavingNewCategory = 'loading';
    },
    [saveNewCategory.fulfilled]: (state, action) => {
      state.isSavingNewCategory = 'success';
      state.newCategory = action.payload;
    },
    [saveNewCategory.rejected]: (state) => {
      state.isSavingNewCategory = 'failed';
    }
  }
});

//to change
export const definirCategoriasSelector = (state) => state.definirCategoriasData;
//to change

//to change
export default definirCategoriasDataSlice.reducer;
