import { MTP_BASE_URL } from 'apiRoutes';
import { Auth } from 'aws-amplify';
import { handleErrors, http } from 'services/http-wrapper';

//=========GET USER DATA=============//
export const ApiGetUserData = async () => {
  try {
    let user = await Auth.currentSession();
    // console.log(user.getIdToken().getJwtToken());
    const api = await http.get(
      `${MTP_BASE_URL}/userdata/get`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetUserAuthorized = async (id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/userdata/${id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};
