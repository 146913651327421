//=========GET ALL COMPANY LIST=============//

import { MTP_BASE_URL } from 'apiRoutes';
import { Auth } from 'aws-amplify';
import { handleErrors, http } from 'services/http-wrapper';

export const ApiGetUserList = async ({ client_id, page, per_page }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/records/user/list?client_id=${client_id}&page=${
        page + 1
      }&per_page=${per_page}`,
      user.getIdToken().getJwtToken(),
      { page },
      { per_page }
    );
    // console.log(user.getIdToken().getJwtToken());
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiUpdateUser = async ({ data, id }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.put(
      `${MTP_BASE_URL}/records/user/update/${id}`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiDeleteUserFromClient = async ({ id }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${MTP_BASE_URL}/records/user/delete/${id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetPerfilList = async () => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/records/perfil/list`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetUsername = async ({ email }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/records/user_cognito/get?email=${email}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//=========SAVE USER DETAILS=============//

export const ApiSaveUser = async (data) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${MTP_BASE_URL}/records/user/save`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//============DASHBOARD CREATION [4]================//

export const ApiGetClientDashboards = async ({ client }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/quicksightclient/client_dashboard_list?client_id=${client}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetDashboardOptions = async () => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/quicksightclient/dashboard?page=1&per_page=50`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiCreateClientDashboard = async (data) => {
  //console.log('Na ApiCreateClientDashboard - data:', data);
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${MTP_BASE_URL}/quicksightclient/client_dashboard_register`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//============DASHBOARD FOR USERS CREATION [3]================//

export const ApiGetUserDashboards = async ({ client }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/quicksightclient/user_dashboard_list?user_id=${client}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiCreateUserDashboard = async (data) => {
  //console.log('Na ApiCreateClientDashboard - data:', data);
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${MTP_BASE_URL}/quicksightclient/user_dashboard_register`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};
