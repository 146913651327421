import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ApiGetNewTypes,
  ApiGetAskedTypes,
  ApiSaveAskedTypes,
  ApiDeleteAskedTypes
} from './solicitarDocumentos';

export const getNewTypes = createAsyncThunk('api/getnew', async (id) => {
  let response = await ApiGetNewTypes(id);
  return response;
});

export const getAskedTypes = createAsyncThunk('api/getasked', async (id) => {
  let response = await ApiGetAskedTypes(id);
  return response;
});

export const saveAskedTypes = createAsyncThunk(
  'api/saveasked',
  async (values) => {
    let response = await ApiSaveAskedTypes(values);
    return response;
  }
);

export const deleteAskedTypes = createAsyncThunk(
  'api/deleteasked',
  async (key) => {
    let response = await ApiDeleteAskedTypes(key);
    return response;
  }
);

export const solicitarDocumentosDataSlice = createSlice({
  name: 'solicitardocumentos',
  initialState: {
    //getNewTypes:
    isLoadingNewTypes: false,
    newTypes: false,
    //getAskedTypes:
    askedTypes: [],
    isLoadingAskedTypes: false,
    //saveAskedTypes:
    isSavingAskedTypes: false,
    savedAskedTypes: false
  },
  reducers: {},
  extraReducers: {
    //getNewTypes:
    [getNewTypes.pending]: (state) => {
      state.isLoadingNewTypes = 'loading';
    },
    [getNewTypes.fulfilled]: (state, action) => {
      state.isLoadingNewTypes = 'success';
      state.newTypes = action.payload;
    },
    [getNewTypes.rejected]: (state) => {
      state.isLoadingNewTypes = 'failed';
    },
    //getAskedTypes:
    [getAskedTypes.pending]: (state) => {
      state.isLoadingAskedTypes = 'loading';
    },
    [getAskedTypes.fulfilled]: (state, action) => {
      state.isLoadingAskedTypes = 'success';
      state.askedTypes = action.payload;
    },
    [getAskedTypes.rejected]: (state) => {
      state.isLoadingAskedTypes = 'failed';
    },
    //saveAskedTypes:
    [saveAskedTypes.pending]: (state) => {
      state.isSavingAskedTypes = 'loading';
    },
    [saveAskedTypes.fulfilled]: (state, action) => {
      state.isSavingAskedTypes = 'success';
      state.savedAskedTypes = action.payload;
    },
    [saveAskedTypes.rejected]: (state) => {
      state.isSavingAskedTypes = 'failed';
    }
  }
});

export const solicitarDocumentosSelector = (state) =>
  state.solicitarDocumentosData;

export default solicitarDocumentosDataSlice.reducer;
