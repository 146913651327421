import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ApiGetFiscalTable,
  ApiGetContribTable,
  ApiGetSped,
  ApiGetSpedtype,
  ApiGetSpedEndpoint,
  ApiGetSpedCruzaAnaliseType,
  ApiGetSpedCruzaEndpoint,
  ApiGetSpedCruzaAnaliseContribType,
  ApiGetSpedExecStatus,
  ApiGetSpedExecEndpoint,
  ApiGetSpedBatchExec,
  ApiGetQuadroEndpoint,
  ApiGetSpedDownloadEndpoint,
  ApiGetConsultaExec,
  ApiGetConsultaByPhase,
  ApiDeleteSped,
  ApiGetSpedAnaliseType
} from './sped.api';

export const getSpedtype = createAsyncThunk('api/spedtypes', async () => {
  let response = await ApiGetSpedtype();
  return response;
});

export const getSped = createAsyncThunk(
  'api/sped',
  async ({
    companyid,
    type_sped,
    status,
    DataInicio,
    DataFim,
    page,
    per_page
  }) => {
    let response = await ApiGetSped({
      companyid,
      type_sped,
      status,
      DataInicio,
      DataFim,
      page,
      per_page
    });
    return response;
  }
);

export const getSpedFiscalTable = createAsyncThunk(
  'api/spedtable',
  async ({ id_sped, table, page, per_page }) => {
    let response = await ApiGetFiscalTable({ id_sped, table, page, per_page });
    return response;
  }
);

export const getSpedContribTable = createAsyncThunk(
  'api/spedcontribtable',
  async ({ id_sped, table, page, per_page }) => {
    let response = await ApiGetContribTable({ id_sped, table, page, per_page });
    return response;
  }
);

export const getSpedCruzaAnaliseType = createAsyncThunk(
  'api/spedcruzatypes',
  async ({ type_sped, table }) => {
    let response = await ApiGetSpedCruzaAnaliseType({ type_sped, table });
    return response;
  }
);
export const getSpedAnaliseType = createAsyncThunk(
  'api/spedanalisetypes',
  async ({ type_sped, table }) => {
    let response = await ApiGetSpedAnaliseType({ type_sped, table });
    return response;
  }
);

export const getSpedCruzaAnaliseContribType = createAsyncThunk(
  'api/spedcruzacontribtypes',
  async ({ type_sped, table }) => {
    let response = await ApiGetSpedCruzaAnaliseContribType({
      type_sped,
      table
    });
    return response;
  }
);

export const getSpedEndpoint = createAsyncThunk(
  'api/spedendpoint',
  async ({ endpoint, id_sped, table, per_page, page }) => {
    let response = await ApiGetSpedEndpoint({
      endpoint,
      id_sped,
      table,
      per_page,
      page
    });
    return response;
  }
);

export const getSpedDownloadEndpoint = createAsyncThunk(
  'api/speddownloadendpoint',
  async ({ endpoint, id_sped, table, per_page, page }) => {
    let response = await ApiGetSpedDownloadEndpoint({
      endpoint,
      id_sped,
      table,
      per_page,
      page
    });
    return response;
  }
);

export const getSpedExecEndpoint = createAsyncThunk(
  'api/spedexecendpoint',
  async ({ endpoint, id_sped }) => {
    let response = await ApiGetSpedExecEndpoint({
      endpoint,
      id_sped
    });
    return response;
  }
);

export const getSpedCruzaEndpoint = createAsyncThunk(
  'api/spedcruzaendpoint',
  async ({ endpoint, id_sped, page, per_page }) => {
    let response = await ApiGetSpedCruzaEndpoint({
      endpoint,
      id_sped,
      page,
      per_page
    });
    return response;
  }
);

export const getSpedCruzaDownloadEndpoint = createAsyncThunk(
  'api/spedcruzadownloadendpoint',
  async ({ endpoint, id_sped, page, per_page }) => {
    let response = await ApiGetSpedCruzaEndpoint({
      endpoint,
      id_sped,
      page,
      per_page
    });
    return response;
  }
);

export const getSpedExecStatusEndpoint = createAsyncThunk(
  'api/spedexecstatusendpoint',
  async ({ id_sped, operation }) => {
    let response = await ApiGetSpedExecStatus({ id_sped, operation });
    return response;
  }
);
export const getSpedBatchExec = createAsyncThunk(
  'api/spedbatchexec',
  async ({ company_id, initial_date, final_date }) => {
    let response = await ApiGetSpedBatchExec({
      company_id,
      initial_date,
      final_date
    });
    return response;
  }
);

export const getQuadroEndpoint = createAsyncThunk(
  'api/quadroendpoint',
  async ({
    endpoint,
    company_id,
    initial_date,
    final_date,
    page,
    per_page
  }) => {
    let response = await ApiGetQuadroEndpoint({
      endpoint,
      company_id,
      initial_date,
      final_date,
      page,
      per_page
    });
    return response;
  }
);

export const getQuadroDownloadEndpoint = createAsyncThunk(
  'api/quadrodownloadendpoint',
  async ({
    endpoint,
    company_id,
    initial_date,
    final_date,
    page,
    per_page
  }) => {
    let response = await ApiGetQuadroEndpoint({
      endpoint,
      company_id,
      initial_date,
      final_date,
      page,
      per_page
    });
    return response;
  }
);

export const getConsultaExec = createAsyncThunk(
  'api/consultaexec',
  async ({ company_id }) => {
    let response = await ApiGetConsultaExec({
      company_id
    });
    return response;
  }
);

export const getConsultaByPhase = createAsyncThunk(
  'api/consultabyphase',
  async ({ company_id, phase, analisys_id }) => {
    let response = await ApiGetConsultaByPhase({
      company_id,
      phase,
      analisys_id
    });
    return response;
  }
);

export const deleteSped = createAsyncThunk('api/deletesped', async ({ id }) => {
  let response = await ApiDeleteSped({ id });
  return response;
});

export const spedtypeSlice = createSlice({
  name: 'spedtype',
  initialState: {
    spedtypes: [],
    isLoadingSpedTypes: false,
    isLoadingSped: false,
    sped: [],
    spedcruzaanalisetype: [],
    spedanalisetype: [],
    spedcruzaanalisecontribtype: [],
    spedendpoint: [],
    speddownloadendpoint: [],
    isloadingSpedendpoint: false,
    spedcruzaendpoint: [],
    spedcruzadownloadendpoint: [],
    isloadingSpedcruzaendpoint: false,
    spedexecendpoint: {},
    isLoadingSpedExec: false,
    consultaExec: [],
    isLoadingConsultaExec: false,
    consultaByPhase: [],
    isLoadingConsultaByPhase: false,
    spedexecstatusendpoint: {},
    IsSpedexecstatusendpoint: false,
    fiscaltable: [],
    contribtable: [],
    isLoadingFiscalTable: false,
    isLoadingContribTable: false,
    spedbatchexec: {},
    isLoadingSpedBatchExec: false,
    quadroendpoint: [],
    quadropaginatedendpoint: [],
    isloadingQuadroendpoint: false,
    isDeletingSped: false
  },
  reducers: {},
  extraReducers: {
    [getSpedtype.pending]: (state) => {
      state.isLoadingSpedTypes = 'loading';
    },
    [getSpedtype.fulfilled]: (state, action) => {
      state.isLoadingSpedTypes = 'success';
      state.spedtypes = action.payload;
    },
    [getSpedtype.rejected]: (state) => {
      state.isLoadingSpedTypes = 'failed';
    },
    [getSped.pending]: (state) => {
      state.isLoadingSped = 'loading';
    },
    [getSped.fulfilled]: (state, action) => {
      state.isLoadingSped = 'success';
      state.sped = action.payload;
    },
    [getSped.rejected]: (state) => {
      state.isLoadingSped = 'failed';
    },
    [getSpedFiscalTable.pending]: (state) => {
      state.isLoadingFiscalTable = 'loading';
    },
    [getSpedFiscalTable.fulfilled]: (state, action) => {
      state.isLoadingFiscalTable = 'success';
      state.fiscaltable = action.payload;
    },
    [getSpedFiscalTable.rejected]: (state) => {
      state.isLoadingFiscalTable = 'failed';
    },
    [getSpedContribTable.pending]: (state) => {
      state.isLoadingContribTable = 'loading';
    },
    [getSpedContribTable.fulfilled]: (state, action) => {
      state.isLoadingContribTable = 'success';
      state.contribtable = action.payload;
    },
    [getSpedContribTable.rejected]: (state) => {
      state.isLoadingContribTable = 'failed';
    },
    [getSpedCruzaAnaliseType.fulfilled]: (state, action) => {
      state.spedcruzaanalisetype = action.payload;
    },
    [getSpedAnaliseType.fulfilled]: (state, action) => {
      state.spedanalisetype = action.payload;
    },
    [getSpedCruzaAnaliseContribType.fulfilled]: (state, action) => {
      state.spedcruzaanalisecontribtype = action.payload;
    },
    [getSpedEndpoint.pending]: (state) => {
      state.isloadingSpedendpoint = 'loading';
    },
    [getSpedEndpoint.fulfilled]: (state, action) => {
      state.isloadingSpedendpoint = 'success';
      state.spedendpoint = action.payload;
      state.speddownloadendpoint = action.payload;
    },
    [getSpedEndpoint.rejected]: (state) => {
      state.isloadingSpedendpoint = 'failed';
    },
    [getSpedDownloadEndpoint.fulfilled]: (state, action) => {
      state.isloadingSpedendpoint = 'success';
      state.speddownloadendpoint = action.payload;
    },
    [getSpedCruzaEndpoint.pending]: (state) => {
      state.isloadingSpedcruzaendpoint = 'loading';
    },
    [getSpedCruzaEndpoint.fulfilled]: (state, action) => {
      state.isloadingSpedcruzaendpoint = 'success';
      state.spedcruzaendpoint = action.payload;
    },
    [getSpedCruzaEndpoint.rejected]: (state) => {
      state.isloadingSpedcruzaendpoint = 'failed';
    },
    [getSpedCruzaDownloadEndpoint.fulfilled]: (state, action) => {
      state.isloadingSpedcruzaendpoint = 'success';
      state.spedcruzadownloadendpoint = action.payload;
    },
    [getSpedExecEndpoint.pending]: (state) => {
      state.isLoadingSpedExec = 'loading';
    },
    [getSpedExecEndpoint.fulfilled]: (state, action) => {
      state.isLoadingSpedExec = 'success';
      state.spedexecendpoint = action.payload;
    },
    [getSpedExecEndpoint.rejected]: (state) => {
      state.isLoadingSpedExec = 'failed';
    },
    [getSpedExecStatusEndpoint.pending]: (state) => {
      state.IsSpedexecstatusendpoint = 'loading';
    },
    [getSpedExecStatusEndpoint.fulfilled]: (state, action) => {
      state.IsSpedexecstatusendpoint = 'success';
      state.spedexecstatusendpoint = action.payload;
    },
    [getSpedExecStatusEndpoint.rejected]: (state) => {
      state.IsSpedexecstatusendpoint = 'failed';
    },
    [getSpedBatchExec.pending]: (state) => {
      state.isLoadingSpedBatchExec = 'loading';
    },
    [getSpedBatchExec.fulfilled]: (state, action) => {
      state.isLoadingSpedBatchExec = 'success';
      state.spedbatchexec = action.payload;
    },
    [getSpedBatchExec.rejected]: (state) => {
      state.isLoadingSpedBatchExec = 'failed';
    },
    [getQuadroEndpoint.pending]: (state) => {
      state.isloadingQuadroendpoint = 'loading';
    },
    [getQuadroEndpoint.fulfilled]: (state, action) => {
      state.isloadingQuadroendpoint = 'success';
      state.quadroendpoint = action.payload;
      state.quadropaginatedendpoint = action.payload;
    },
    [getQuadroEndpoint.rejected]: (state) => {
      state.isloadingQuadroendpoint = 'failed';
    },
    [getQuadroDownloadEndpoint.pending]: (state) => {
      state.isloadingQuadroDownloadendpoint = 'loading';
    },
    [getQuadroDownloadEndpoint.fulfilled]: (state, action) => {
      state.isloadingQuadroDownloadendpoint = 'success';
      state.quadropaginatedendpoint = action.payload;
    },
    [getQuadroDownloadEndpoint.rejected]: (state) => {
      state.isloadingQuadroDownloadendpoint = 'failed';
    },
    [getConsultaExec.pending]: (state) => {
      state.isLoadingConsultaExec = 'loading';
    },
    [getConsultaExec.fulfilled]: (state, action) => {
      state.isLoadingConsultaExec = 'success';
      state.consultaExec = action.payload;
    },
    [getConsultaExec.rejected]: (state) => {
      state.isLoadingConsultaExec = 'failed';
    },
    [getConsultaByPhase.pending]: (state) => {
      state.isLoadingConsultaByPhase = 'loading';
    },
    [getConsultaByPhase.fulfilled]: (state, action) => {
      state.isLoadingConsultaByPhase = 'success';
      state.consultaByPhase = action.payload;
    },
    [getConsultaByPhase.rejected]: (state) => {
      state.isLoadingConsultaByPhase = 'failed';
    },
    [deleteSped.pending]: (state) => {
      state.isDeletingSped = 'loading';
    },
    [deleteSped.fulfilled]: (state) => {
      state.isDeletingSped = 'success';
    },
    [deleteSped.rejected]: (state) => {
      state.isDeletingSped = 'failed';
    }
  }
});

export const spedSelector = (state) => state.spedtype;
export default spedtypeSlice.reducer;
