import React, { useState, useEffect } from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import MiasEye from '../../../assets/eye-mia.gif';
import MiasEyeCrying from '../../../assets/eye-mia-crying.gif';
import './style.css';

function TableExcelS3({ data }) {
  const [columnTitles, setColumnTitles] = useState([]);
  const [rows, setRows] = useState([]);
  const [contingValues, setContingValues] = useState([]);
  const [oportValues, setOportValues] = useState([]);
  const [atencValues, setAtencValues] = useState([]);
  const [atencPlusContingValues, setAtencPlusContingValues] = useState([]);
  const [aCompensarValues, setACompensarValues] = useState([]);
  const [compensadoValues, setCompensadoValues] = useState([]);
  const [pickedTipoDePonto, setPickedTipoDePonto] = useState();

  const formatCurrency = (number) => {
    let onNumber = parseFloat(number);
    const formattedCurrency = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(onNumber);
    return formattedCurrency;
  };

  useEffect(() => {
    if (data) {
      const hiddenColumns = [
        'Valor à Compensar',
        'Percentual à Compensar',
        'Valor Total Compensado/Utilizado',
        'Percentual Compensado/ Utilizado',
        'Saldo Disponível para Compensação'
      ];
      const columns = data[0].filter((item) => !hiddenColumns.includes(item));
      setColumnTitles(columns);
      let preBody = data.slice(1);
      let body = [];
      preBody.forEach((row) => {
        if (row.length >= 16) {
          body.push(row);
        } else if (row.length < 16) {
          let rowAdded = row;
          rowAdded.push('');
          body.push(rowAdded);
        }
      });
      //todas as linhas:
      let cleanBody = body.map((subArray) => {
        const row = subArray.filter((value) => value !== '');
        return row;
      });
      setRows(cleanBody);
      //valores de atenção:
      let atenc = body.filter((subArray) => {
        const type =
          subArray[4] === 'Atenção' ||
          subArray[4] === 'Atençao' ||
          subArray[4] === 'Atencao' ||
          subArray[4] === 'Atencão';
        if (type) {
          return subArray;
        }
      });
      let atencNumbers = atenc.map((subArray) => {
        const number = Number(subArray[6]);
        return number;
      });
      const atencSum = atencNumbers.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      let formatAtencSum = formatCurrency(atencSum);
      setAtencValues(formatAtencSum);
      //valores de contingência:
      let conting = body.filter((subArray) => {
        const type =
          subArray[4] === 'Contingência' || subArray[4] === 'Contingencia';
        if (type) {
          return subArray;
        }
      });
      let contingNumbers = conting.map((subArray) => {
        const number = Number(subArray[6]);
        return number;
      });
      const contingSum = contingNumbers.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      let formatContingSum = formatCurrency(contingSum);
      setContingValues(formatContingSum);
      let atencPlusConting = atencSum + contingSum;
      let formatAtencPlusConting = formatCurrency(atencPlusConting);
      setAtencPlusContingValues(formatAtencPlusConting);
      //valores de oportunidade:
      let oport = body.filter((subArray) => {
        const type = subArray[4] === 'Oportunidade';
        if (type) {
          return subArray;
        }
      });
      let oportNumbers = oport.map((subArray) => {
        const number = Number(subArray[6]);
        return number;
      });
      const oportSum = oportNumbers.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      let formatOportSum = formatCurrency(oportSum);
      setOportValues(formatOportSum);
      //valores de a compensar:
      let acompensar = body.filter((subArray) => {
        const type = subArray[8] !== '';
        if (type) {
          return subArray;
        }
      });
      let acompensarNumbers = acompensar.map((subArray) => {
        const number = Number(subArray[8]);
        return number;
      });
      const acompensarSum = acompensarNumbers.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      if (typeof acompensarSum === 'number' && acompensarSum >= 0) {
        let formatAcompensarSum = formatCurrency(acompensarSum);
        setACompensarValues(formatAcompensarSum);
      } else {
        setACompensarValues('Não definido');
      }
      //valores compensado:
      let compensado = body.filter((subArray) => {
        const type = subArray[7] !== '';
        if (type) {
          return subArray;
        }
      });
      let compensadoNumbers = compensado.map((subArray) => {
        const number = Number(subArray[7]);
        return number;
      });
      const compensadoSum = compensadoNumbers.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      if (typeof compensadoSum === 'number' && compensadoSum >= 0) {
        let formatCompensadoSum = formatCurrency(compensadoSum);
        setCompensadoValues(formatCompensadoSum);
      } else {
        setCompensadoValues('Não definido');
      }
    }
  }, [data]);

  const checkLink = (string) => {
    const prefixes = 'http';
    if (string) {
      for (const prefix of prefixes) {
        if (string.startsWith(prefix)) {
          return true;
        }
      }
      return false;
    }
  };

  const filterRowsByTipo = (tipo) => {
    if (data) {
      if (tipo === 'Todos') {
        let preBody = data.slice(1);
        let body = [];
        preBody.forEach((row) => {
          if (row.length >= 16) {
            body.push(row);
          } else if (row.length < 16) {
            let rowAdded = row;
            rowAdded.push('');
            body.push(rowAdded);
          }
        });
        let cleanBody = body.map((subArray) => {
          const row = subArray.filter((value) => value !== '');
          return row;
        });
        setRows(cleanBody);
      } else {
        const hiddenColumns = [
          'Percentual à Compensar',
          'Percentual Compensado/ Utilizado',
          'Saldo Disponível para Compensação'
        ];
        const columns = data[0].filter((item) => !hiddenColumns.includes(item));
        setColumnTitles(columns);
        let preBody = data.slice(1);
        let body = [];
        preBody.forEach((row) => {
          if (row.length >= 16) {
            body.push(row);
          } else if (row.length < 16) {
            let rowAdded = row;
            rowAdded.push('');
            body.push(rowAdded);
          }
        });
        let cleanBody = body.map((subArray) => {
          const row = subArray.filter((value) => value !== '');
          return row;
        });
        let tipoArray = cleanBody.filter((subArray) => {
          const type = subArray[4] === tipo;
          if (type) {
            return subArray;
          }
        });
        setRows(tipoArray);
      }
    }
  };

  function ImageMask({ cell }) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a href={cell} target="_blank" rel="noreferrer noopener">
          <img src={MiasEye} style={{ width: '50px' }} />
        </a>
      </div>
    );
  }

  function ImageMaskAlt({ cell }) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a href={cell} target="_blank" rel="noreferrer noopener">
          <img src={MiasEyeCrying} style={{ width: '50px' }} />
        </a>
      </div>
    );
  }

  function TruncateText({ cell, position }) {
    const [textValue, setTextValue] = useState('');
    const [textSwitch, setTextSwitch] = useState(false);
    const lengthTolerance = 45;
    const wordsTolerance = 18;
    const length = cell?.length;
    const full = cell;
    const words = cell.split(' '); //split the string into an array of words
    const slicedWords = words.slice(0, wordsTolerance); //select a range of words
    const slicedText = slicedWords.join(' '); //join the words back into a string
    const checkCurrency = (cell) => {
      const sanitizedValue = cell.replace(',', '');
      const numericValue = +sanitizedValue;
      return !isNaN(numericValue) && isFinite(numericValue);
    };
    const formatCurrency = (number) => {
      let onNumber = parseFloat(number);
      const formattedCurrency = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(onNumber);
      return formattedCurrency;
    };
    useEffect(() => {
      if (!textSwitch) {
        setTextValue(slicedText);
      } else if (textSwitch) {
        setTextValue(full);
      }
    }, [textSwitch]);
    const handleTextSwitch = () => {
      setTextSwitch(!textSwitch);
    };
    return (
      <>
        {length && length > lengthTolerance ? (
          <div>
            {textValue} {!textSwitch ? '(...)' : null}
            <span onClick={handleTextSwitch}>
              {!textSwitch ? (
                <AddBoxIcon
                  sx={{
                    fontSize: '15.5px',
                    color: '#bbb',
                    '&:hover': { color: '#999', cursor: 'pointer' }
                  }}
                />
              ) : (
                <IndeterminateCheckBoxIcon
                  sx={{
                    fontSize: '15.5px',
                    color: '#bbb',
                    '&:hover': { color: '#999', cursor: 'pointer' }
                  }}
                />
              )}
            </span>
          </div>
        ) : length ? (
          <div>
            {position === 0
              ? cell
              : checkCurrency(cell)
              ? formatCurrency(cell)
              : cell}
          </div>
        ) : null}
      </>
    );
  }

  const handleSelectTipoDePonto = (tipo) => {
    setPickedTipoDePonto(tipo);
    filterRowsByTipo(tipo);
  };

  const tipoDePontoSelect = () => {
    const listOfTiposDePonto = [
      'Todos',
      'Contingência',
      'Atenção',
      'Oportunidade'
    ];
    return (
      <select
        className="exceljs-menu-dropdown"
        value={pickedTipoDePonto}
        onChange={(event) => handleSelectTipoDePonto(event.target.value)}
      >
        <option disabled selected>
          Escolha um Tipo de Ponto
        </option>
        {listOfTiposDePonto.map((tipo) => (
          <option key={tipo} value={tipo}>
            {tipo}
          </option>
        ))}
      </select>
    );
  };

  function EmptySection() {
    const container = {
      marginTop: '160px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    };
    const iconWrapper = {
      textAlign: 'center',
      width: '100%',
      height: 'fit-content',
      padding: '2px 0px 2px 0px',
      marginBottom: '4px'
    };
    const icon = {
      fontSize: '72px',
      color: '#DDDDDD'
    };
    const firstLine = {
      textAlign: 'center',
      width: '100%',
      height: 'fit-content',
      padding: '2px 0px 2px 0px',
      marginBottom: 0,
      fontSize: '20px',
      fontWeight: '600',
      color: '#5D5E66'
    };
    const secondLine = {
      textAlign: 'center',
      width: '100%',
      height: 'fit-content',
      padding: '2px 0px 2px 0px',
      marginBottom: 0,
      fontSize: '16px',
      color: '#B9B9B9'
    };
    return (
      <div style={container}>
        <div style={iconWrapper}>
          <BackupTableIcon sx={icon} />
        </div>
        <div style={firstLine}>Não há linhas para a opção selecionada</div>
        <div style={secondLine}>
          <div>{`Selecione outro filtro para o Sumário`}</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          gridTemplateColumns: '3fr 3fr 2fr',
          display: 'grid',
          grid: '1fr 1fr 1fr',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0
        }}
      >
        <div className="exceljs-grid-column">
          {(contingValues.length ||
            oportValues.length ||
            atencValues.length) && (
            <div className="exceljs-head-above-table">
              <div className="exceljs-head-div-flex">
                <div className="exceljs-head-div-title sum-a">
                  Soma das Atenções:
                </div>
                <div className="exceljs-head-div-content sum-c">
                  {atencPlusContingValues}
                </div>
              </div>
              <div className="exceljs-head-div-flex">
                <div className="exceljs-head-div-title sum-a">
                  Soma das Oportunidades:
                </div>
                <div className="exceljs-head-div-content sum-c">
                  {oportValues}
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          {(contingValues.length ||
            oportValues.length ||
            atencValues.length) && (
            <div className="exceljs-head-above-table">
              <div className="exceljs-head-div-flex">
                <div className="exceljs-head-div-title sum-b">
                  Total à compensar:
                </div>
                <div className="exceljs-head-div-content sum-d">
                  {aCompensarValues}
                </div>
              </div>
              <div className="exceljs-head-div-flex">
                <div className="exceljs-head-div-title sum-b">
                  Total compensado:
                </div>
                <div className="exceljs-head-div-content sum-d">
                  {compensadoValues}
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="exceljs-head-above-table-filters">
            <div className="exceljs-head-div-flex">
              <div className="exceljs-head-div-title">Filtrar por:</div>
              <div className="exceljs-head-div-content">
                {tipoDePontoSelect()}
              </div>
            </div>
          </div>
        </div>
      </div>
      {columnTitles?.length && rows?.length ? (
        <table
          style={{ margin: '120px 0px 0px 0px' }}
          className={'exceljs-dash-table'}
        >
          <thead className={'exceljs-dash-head'}>
            <tr>
              {columnTitles.map((title, index) => (
                <th key={index}>{title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <tr key={i}>
                {row[4] === 'Contingência' ||
                row[4] === 'Contingencia' ||
                row[4] === 'Atenção' ||
                row[4] === 'Atençao' ||
                row[4] === 'Atencao' ||
                row[4] === 'Atencão'
                  ? row.map((content, j) => (
                      <td
                        className={
                          checkLink(content)
                            ? `exceljs-dash-cell-tiny`
                            : content.length < 21
                            ? `exceljs-dash-cell-tiny`
                            : content.length < 40
                            ? `exceljs-dash-cell-small`
                            : content.length < 80
                            ? `exceljs-dash-cell-medium`
                            : content.length >= 80
                            ? `exceljs-dash-cell-large`
                            : `exceljs-dash-cell-medium`
                        }
                        key={j}
                      >
                        {checkLink(content) ? (
                          <ImageMask cell={content} />
                        ) : (
                          <TruncateText cell={content} position={j} />
                        )}
                      </td>
                    ))
                  : row.map((content, k) => (
                      <td
                        className={
                          checkLink(content)
                            ? `exceljs-dash-cell-tiny`
                            : content.length < 21
                            ? `exceljs-dash-cell-tiny`
                            : content.length < 40
                            ? `exceljs-dash-cell-small`
                            : content.length < 80
                            ? `exceljs-dash-cell-medium`
                            : content.length >= 80
                            ? `exceljs-dash-cell-large`
                            : `exceljs-dash-cell-medium`
                        }
                        key={k}
                      >
                        {checkLink(content) ? (
                          <ImageMaskAlt cell={content} />
                        ) : (
                          <TruncateText cell={content} position={k} />
                        )}
                      </td>
                    ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>
          <EmptySection />
        </div>
      )}
    </>
  );
}

export default TableExcelS3;
