import { Autocomplete, TextField } from '@mui/material';
import PopperCustom from '../PopperCustom';
import CircularProgress from '@mui/material/CircularProgress';

function CustomComboBox({
  options,
  defaultValue,
  value,
  setValue,
  label,
  optionsLabel,
  isOptionEqualToValue,
  sx,
  size,
  innerSx,
  disabled
}) {
  return (
    <>
      {options?.length === 0 ? (
        <CircularProgress />
      ) : (
        <Autocomplete
          disabled={disabled}
          disablePortal
          disableClearable
          value={value}
          options={options}
          onChange={setValue}
          getOptionLabel={optionsLabel}
          defaultValue={defaultValue}
          isOptionEqualToValue={isOptionEqualToValue}
          sx={sx}
          size={size}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              sx={innerSx}
            />
          )}
          PopperComponent={PopperCustom}
        />
      )}
    </>
  );
}

export default CustomComboBox;
