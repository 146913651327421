import React from 'react';
//import { Container } from '@mui/material';
//MUI
//COMPONENTS
//SERVICES
//STYLING
import './style.css';
//import theme from '../../theme';

function MTWProductSection({ padding, section, children }) {
  //--------------------JSX Fn Compon--------------------

  //--------------------JSX--------------------
  const mtwProductSection = {
    padding: padding ? padding : 0
  };

  const mtwProductSectionTable = {
    padding: '0px 0px 0px 0px'
  };

  const mtwProductSectionFooter = {
    padding: '50px 0px 50px 0px'
  };

  const mtwProductSectionFooterLow = {
    marginTop: '100px',
    padding: '0px 0px 50px 0px'
  };

  const mtwStyle = (section) => {
    switch (section) {
      case 'table':
        return mtwProductSectionTable;
      case 'footer':
        return mtwProductSectionFooter;
      case 'lowerFooter':
        return mtwProductSectionFooterLow;

      default:
        return mtwProductSection;
    }
  };

  return <section style={mtwStyle(section)}>{children}</section>;
}

export default MTWProductSection;
