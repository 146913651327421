import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
//MUI
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
//COMPONENTS
import BoxedButton from '../../../components/MTWActions/ButtonBoxed';
import EmbedQSearchBar from '../components/EmbedQSearchBar';
import ButtonFloating from '../../../components/MTWActions/ButtonFloating';
import StandardLayout from 'components/Layout/StandardLayout/StandardLayout';
import MTWProductHeaderLite from '../../../components/ProductHeaderLite';
import MTWProductSection from 'components/ProductSection';
import MTWFooter from 'components/Layout/Footer';
//REDUX
import {
  getCompanyList,
  companySelector
} from 'features/cadastroFeatures/Company/company.store';
import { useDispatch, useSelector } from 'react-redux';
//SERVICES
import { maskedValue } from 'services/maskedValue';
//STYLE
import { useNavigate } from 'react-router-dom';

const MakeBIHome = () => {
  //Redux:
  const { companies } = useSelector(companySelector);
  //localStorage:
  const [importedCompany, setImportedCompany] = useState('');
  const [companyDisplayed, setCompanyDisplayed] = useState('');
  const navigate = useNavigate();

  //--------------------Local Storage--------------------
  window.addEventListener('storage', () => {
    let company = window.localStorage.getItem('CURRENT_COMPANY');
    setImportedCompany(company);
  });
  /*Listener do localStorage*/

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanyList());
  }, []);

  useEffect(() => {
    if (companies?.items) {
      let inDetails = companies?.items.filter(
        (row) => row.IdEmpresa === importedCompany
      );
      let companyNameAndCode = `${inDetails[0]?.Nome} − ${maskedValue(
        inDetails[0]?.Cnpj
      )}`;
      setCompanyDisplayed(companyNameAndCode);
    }
  }, [companies, importedCompany]);
  /*companyDetails*/

  const [hiddenPageFrame, setHiddenPageFrame] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const onHiddenPageFrame = () => {
    setHiddenPageFrame(!hiddenPageFrame);
  };

  const handleToggleFullScreen = () => {
    if (!isFullScreen) {
      onHiddenPageFrame();
      document.documentElement.requestFullscreen();
    } else {
      onHiddenPageFrame();
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
      setTimeout(() => {
        window.scrollTo({
          top: 42,
          behavior: 'smooth'
        });
      }, 300);
    }
    setIsFullScreen(!isFullScreen);
  };

  const containerSize = (hiddenPageFrame) => {
    if (!hiddenPageFrame) {
      return 'lg';
    } else if (hiddenPageFrame) {
      return 'xl';
    }
  };

  return (
    <StandardLayout hiddenNavbar={hiddenPageFrame}>
      <Container
        sx={{ padding: '0px 0px 50px 0px' }}
        maxWidth={containerSize(hiddenPageFrame)}
      >
        <MTWProductHeaderLite
          hidden={hiddenPageFrame}
          page={'make-view-dashboards'}
          pageBack={'/make-view'}
          pageTitle={'Dashboard Cognitivo'}
          buttons={true}
          isolated={false}
          alert={companyDisplayed.length ? false : true}
          cnpj={
            companyDisplayed.length
              ? companyDisplayed
              : 'Escolha uma empresa pela aba "Perfil"'
          }
        >
          <BoxedButton onClick={() => navigate('/make-bi')} page={'make-view'}>
            <div>Dashboards</div>
          </BoxedButton>
        </MTWProductHeaderLite>
        <ButtonFloating onClick={handleToggleFullScreen}>
          {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </ButtonFloating>
        <div>
          <EmbedQSearchBar />
        </div>
      </Container>
      <MTWProductSection section={'footer'}>
        <MTWFooter text={'Make BI'} />
      </MTWProductSection>
    </StandardLayout>
  );
};

export default MakeBIHome;
