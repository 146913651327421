import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApiPostQsDb,
  ApiGetQsdblist,
  ApiGetQsTopiclist,
  ApiGetQsTopicSearch
} from './quicksightDbList.api';

export const getQsDbList = createAsyncThunk('api/qsdashboardlist', async () => {
  let response = await ApiGetQsdblist();
  return response;
});

export const retrieveQsDb = createAsyncThunk(
  'api/qsdashboard',
  async (body) => {
    let response = await ApiPostQsDb(body);
    return response;
  }
);

export const getQsTopicList = createAsyncThunk('api/qstopiclist', async () => {
  let response = await ApiGetQsTopiclist();
  return response;
});

export const getQsTopicSearch = createAsyncThunk(
  'api/qstopicsearch',
  async ({ topicid }) => {
    let response = await ApiGetQsTopicSearch({ topicid });
    return response;
  }
);

export const QsDbListSlice = createSlice({
  name: 'QsDbList',
  initialState: {
    DBList: [],
    isGettingDBList: false,
    isDBListDone: false,
    dashboard: {},
    isGettingDB: false,
    topics: [],
    topicframe: {},
    isGettingTopicSearch: false,
    isLoadingTopics: false,
    isFetching: false,
    isSelectedQS: ''
  },
  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      return state;
    },
    setQuicksight: (state, { payload }) => {
      state.isSelectedQS = payload;
      return state;
    }
  },
  extraReducers: {
    [getQsDbList.pending]: (state) => {
      state.isGettingDBList = 'loading';
      state.isDBListDone = false;
    },
    [getQsDbList.fulfilled]: (state, action) => {
      state.isGettingDBList = 'success';
      state.DBList = action.payload;
      state.isDBListDone = true;
    },
    [getQsDbList.rejected]: (state, action) => {
      state.isGettingDBList = 'failed';
      state.error = action.error;
      state.isDBListDone = true;
    },
    [retrieveQsDb.pending]: (state) => {
      state.isGettingDB = 'loading';
      state.isFetching = true;
    },
    [retrieveQsDb.fulfilled]: (state, action) => {
      state.isGettingDB = 'success';
      state.dashboard = action.payload;
      state.isFetching = false;
    },
    [retrieveQsDb.rejected]: (state, action) => {
      state.isGettingDB = 'failed';
      state.error = action.error;
    },
    [getQsTopicList.pending]: (state) => {
      state.isLoadingTopics = 'loading';
    },
    [getQsTopicList.fulfilled]: (state, action) => {
      state.isLoadingTopics = 'success';
      state.topics = action.payload;
    },
    [getQsTopicList.rejected]: (state) => {
      state.isLoadingTopics = 'failed';
    },
    [getQsTopicSearch.pending]: (state) => {
      state.isGettingTopicSearch = 'loading';
    },
    [getQsTopicSearch.fulfilled]: (state, action) => {
      state.isGettingTopicSearch = 'success';
      state.topicframe = action.payload;
    },
    [getQsTopicSearch.rejected]: (state) => {
      state.isGettingTopicSearch = 'failed';
    }
  }
});

export const { clearState, setQuicksight } = QsDbListSlice.actions;
export const QsDbListSelector = (state) => state.QsDbList;
export default QsDbListSlice.reducer;
