import React, { useEffect, useState } from 'react';
import { Container, Modal, Paper, Box, Grid } from '@mui/material';
//MUI
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
//COMPONENTS
import EmbedDashboard from '../components/EmbedDashboard';
import BoxedButton from '../../../components/MTWActions/ButtonBoxed';
import ButtonFloating from '../../../components/MTWActions/ButtonFloating';
import StandardLayout from 'components/Layout/StandardLayout/StandardLayout';
import MTWProductHeaderLite from '../../../components/ProductHeaderLite';
import MTWProductSection from 'components/ProductSection';
import MTWFooter from 'components/Layout/Footer';
import Button from '../../../components/MTWActions/Button';
import DatePickerTypeable from '../../../components/MTWDataInput/DatePickerTypeable';
import FetchingAnimation from 'components/MTWFeedback/Fetching';
//REDUX
import {
  getCompanyList,
  companySelector
} from 'features/cadastroFeatures/Company/company.store';
import { getMakeBiExecuteAnalysis } from 'features/MakeView/makeView.store';
import { useDispatch, useSelector } from 'react-redux';
//SERVICES
import { maskedValue } from 'services/maskedValue';
import { toast } from 'react-toastify';
//STYLE
import { useNavigate } from 'react-router-dom';

const MakeBIHome = () => {
  //Redux:
  const { companies } = useSelector(companySelector);
  //localStorage:
  const [importedCompany, setImportedCompany] = useState('');
  const [companyCnpj, setCompanyCnpj] = useState('');
  const [companyDisplayed, setCompanyDisplayed] = useState('');
  const [sessionCnpjs, setSessionCnpjs] = useState([]);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  //Filters:
  const [selectedInitialDate, setSelectedInitialDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [formattedInitialDate, setFormattedInitialDate] = useState('');
  const [formattedEndDate, setFormattedEndDate] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    if (companies?.items) {
      let cnpjsList = companies?.items.map((company) => company.Cnpj);
      setSessionCnpjs(cnpjsList);
    }
  }, [companies]);

  //--------------------Local Storage--------------------
  window.addEventListener('storage', () => {
    let company = window.localStorage.getItem('CURRENT_COMPANY');
    setImportedCompany(company);
  });
  /*Listener do localStorage*/

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanyList());
  }, []);

  useEffect(() => {
    if (companies?.items) {
      let inDetails = companies?.items.filter(
        (row) => row.IdEmpresa === importedCompany
      );
      setCompanyCnpj(inDetails[0]?.Cnpj);
      let companyNameAndCode = `${inDetails[0]?.Nome} − ${maskedValue(
        inDetails[0]?.Cnpj
      )}`;
      setCompanyDisplayed(companyNameAndCode);
    }
  }, [companies, importedCompany]);
  /*companyDetails*/

  const [hiddenPageFrame, setHiddenPageFrame] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const onHiddenPageFrame = () => {
    setHiddenPageFrame(!hiddenPageFrame);
  };

  const handleToggleFullScreen = () => {
    if (!isFullScreen) {
      onHiddenPageFrame();
      document.documentElement.requestFullscreen();
    } else {
      onHiddenPageFrame();
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
      setTimeout(() => {
        window.scrollTo({
          top: 42,
          behavior: 'smooth'
        });
      }, 300);
    }
    setIsFullScreen(!isFullScreen);
  };

  const containerSize = (hiddenPageFrame) => {
    if (!hiddenPageFrame) {
      return 'lg';
    } else if (hiddenPageFrame) {
      return 'xl';
    }
  };
  const formattedDate = (date) => {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    const formatted = `${year}-${month < 10 ? '0' + month : month}-${
      day < 10 ? '0' + day : day
    }`;
    return formatted;
  };
  // Função para formatar datas no formato YYYY-MM-DD

  useEffect(() => {
    if (selectedInitialDate) {
      const formattedDateInicio = formattedDate(selectedInitialDate);
      setFormattedInitialDate(formattedDateInicio);
    } else {
      setFormattedInitialDate('');
    }

    if (selectedEndDate) {
      const formattedDateFim = formattedDate(selectedEndDate);
      setFormattedEndDate(formattedDateFim);
    } else {
      setFormattedEndDate('');
    }
  }, [selectedInitialDate, selectedEndDate]);

  const handleGetExecuteAnalysis = async () => {
    if (companyCnpj.length > 0) {
      setLoadingButton(true);
      let data = {
        cnpj: companyCnpj,
        data_inicio: formattedInitialDate,
        data_fim: formattedEndDate
      };

      dispatch(getMakeBiExecuteAnalysis(data))
        .then((response) => {
          if (response.error) {
            toast.error('Erro ao executar as análises');
          } else {
            toast.success('Análises executadas com sucesso');
          }
        })
        .catch(() => {
          toast.error('Erro ao executar as análises');
        })
        .finally(() => {
          setLoadingButton(false);
          setModal(false);
          closeModal();
        });
    } else {
      toast.warning('Erro');
    }
  };

  //---------------------------Modal--------------------------
  function openModal() {
    setModal(true);
  }

  function closeModal() {
    setModal(false);
    setSelectedInitialDate('');
    setSelectedEndDate('');
  }

  //--------------------------CSS------------------------------
  let basicPaper = {
    padding: '32px 36px 24px 36px',
    borderRadius: '6px',
    minWidth: '500px',
    maxWidth: '640px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  };

  let iconWrapper = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px',
    width: '40px',
    height: '40px',
    background: '#eee',
    borderRadius: '40px',
    marginBottom: '14px'
  };

  let basicModalTitleWrapper = {
    paddingBottom: '30px'
  };

  let basicModalTitle = {
    fontSize: '20px',
    fontWeight: '600',
    marginLeft: '8px',
    position: 'relative',
    top: '8px'
  };

  let basicButtonsWrapper = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    columnGap: '15px'
  };

  let basicModalSubtitles = {
    fontSize: '13px',
    fontWeight: '700',
    color: '#171717',
    marginBottom: '4px',
    letterSpacing: '0.15px'
  };

  let basicInputDate = {
    padding: '14px',
    background: '#fff',
    borderRadius: '5px',
    border: '1px solid #ccc',
    display: 'flex',
    justifyContent: 'center',
    columnGap: '20px'
  };

  return (
    <StandardLayout minHeight={'600px'} hiddenNavbar={hiddenPageFrame}>
      <Container
        sx={{ padding: '0px 0px 50px 0px' }}
        maxWidth={containerSize(hiddenPageFrame)}
      >
        <MTWProductHeaderLite
          hidden={hiddenPageFrame}
          page={'make-view-dashboards'}
          pageBack={'/make-view'}
          pageTitle={'Dashboards'}
          buttons={true}
          isolated={false}
          alert={companyDisplayed.length ? false : true}
          cnpj={
            companyDisplayed.length > 13
              ? companyDisplayed
              : 'Escolha uma empresa pela aba "Perfil"'
          }
        >
          <BoxedButton onClick={openModal} page={'make-view'}>
            <div>Executar Análises</div>
          </BoxedButton>
          <BoxedButton
            onClick={() => navigate('/make-bi/q-searchbar')}
            page={'make-view'}
            disabled={true}
          >
            <div>Dashboard Cognitivo</div>
          </BoxedButton>
        </MTWProductHeaderLite>

        <Modal open={modal} onClose={closeModal}>
          <Paper sx={basicPaper}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <div style={iconWrapper}>
                <EqualizerOutlinedIcon />
              </div>
              <Box sx={basicModalTitleWrapper}>
                <div style={basicModalTitle}>Executar Análises</div>
              </Box>
            </Box>

            <Box sx={{ padding: '6px 0px 26px 0px' }}>
              <div style={basicModalSubtitles}>Empresa</div>
              <div style={{ color: '#4a3e52' }}>{companyCnpj}</div>
            </Box>

            <Box sx={{ padding: '0px 0px 26px 0px' }}>
              <div style={basicModalSubtitles}>Datas</div>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box sx={basicInputDate}>
                  <DatePickerTypeable
                    date={selectedInitialDate}
                    handleDate={(value) => setSelectedInitialDate(value)}
                    placeholder="Data Inicial"
                  />
                  <DatePickerTypeable
                    date={selectedEndDate}
                    handleDate={(value) => setSelectedEndDate(value)}
                    placeholder="Data Final"
                  />
                </Box>
              </Grid>
            </Box>

            <div>
              <div style={basicButtonsWrapper}>
                <Button
                  outline
                  sx={{ height: '40px' }}
                  text="Cancel"
                  onClick={closeModal}
                >
                  Cancelar
                </Button>
                <Button
                  primary
                  sx={{ height: '40px' }}
                  text="Enviar"
                  onClick={handleGetExecuteAnalysis}
                >
                  {loadingButton ? (
                    <div>
                      <FetchingAnimation />
                    </div>
                  ) : (
                    'Enviar'
                  )}
                </Button>
              </div>
            </div>
          </Paper>
        </Modal>

        <ButtonFloating onClick={handleToggleFullScreen}>
          {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </ButtonFloating>
        <div>
          <EmbedDashboard
            cnpj={companyCnpj}
            sessionCnpjs={sessionCnpjs}
            hiddenPageFrame={hiddenPageFrame}
          />
        </div>
      </Container>
      <MTWProductSection section={'footer'}>
        <MTWFooter text={'Make BI'} />
      </MTWProductSection>
    </StandardLayout>
  );
};

export default MakeBIHome;
