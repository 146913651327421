import { http, handleErrors } from '../../services/http-wrapper';
import { Auth } from 'aws-amplify';

export const ApiGetCategories = async () => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `https://uo8q51ckb3.execute-api.us-east-1.amazonaws.com/list_table/categoria_empresa?page=1&per_page=50`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetCategoriesFiles = async (category) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `https://uo8q51ckb3.execute-api.us-east-1.amazonaws.com/categoria_arquivo/list?id_categoria=${category}&page=1&per_page=50`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiSaveCategoriesFiles = async ({ id, tipo, qtd, obs }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.put(
      `https://uo8q51ckb3.execute-api.us-east-1.amazonaws.com/categoria_arquivo/update?id_categoria=${id}&tipo_arquivo=${tipo}&qtd=${qtd}&obs=${obs}`,
      '',
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    console.log(error);
    // handlerErros(error);
    throw error;
  }
};

export const ApiSaveNewCategory = async (id) => {
  console.log(id);
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `https://uo8q51ckb3.execute-api.us-east-1.amazonaws.com/categoria_arquivo/save`,
      id,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    console.log(error);
    // handlerErros(error);
    throw error;
  }
};
