/* eslint-disable no-undef */
export const MTP_BASE_URL = process.env.REACT_APP_MTP_BASE_URL;
export const ELEMENTAR_BASE_URL = process.env.REACT_APP_ELEMENTAR_BASE_URL;
export const MTP_BUCKET_NAME = process.env.REACT_APP_MAKE_THE_PRICE_BUCKET_NAME;
export const ELEMENTAR_BUCKET_NAME = process.env.REACT_APP_ELEMENTAR_BUCKET;
export const NOTA_AVULSA_BASE_URL = process.env.REACT_APP_NOTA_AVULSA_BASE_URL;
export const HUMAN_LOOP_BASE_URL = process.env.REACT_APP_HUMAN_LOOP_BASE_URL;
export const ELEMENTAR_ANALYSIS_BASE_URL =
  process.env.REACT_APP_ELEMENTAR_ANALYSIS;
export const DLM_BASE_URL = process.env.REACT_APP_DLM_BASE_URL;
export const SECRET_MAN_BASE_URL = process.env.REACT_APP_SECRET_MAN_BASE_URL;
export const SECRET_MAN_KEY = process.env.REACT_APP_SECRET_MAN_KEY;
