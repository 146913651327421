import React from 'react';
//COMPONENTS:
//STYLING:
//import theme from '../../theme';
import './style.css';

function index({ onClick, disable, children }) {
  const nullFn = () => {
    return false;
  };

  return (
    <button className="floating-button" onClick={disable ? nullFn() : onClick}>
      {children}
    </button>
  );
}

export default index;
