import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';
import './style.css';

function DatePickerTypeable({ date, handleDate, placeholder, disabled }) {
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    if (date && !(date instanceof Date)) {
      let valid = new Date(date);
      setStartDate(valid);
    } else if (date && date instanceof Date) {
      setStartDate(date);
    } else if (!date) {
      setStartDate();
    }
  }, [date]);

  return (
    <DatePicker
      locale={ptBR}
      selected={startDate}
      dateFormat="dd/MM/yyyy"
      placeholderText={placeholder}
      onChange={(date) => handleDate(date)}
      disabled={disabled}
    />
  );
}

export default DatePickerTypeable;
