import React from 'react';
//LOGOS:
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
//import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
//import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import LabelIcon from '@mui/icons-material/Label';
import HubIcon from '@mui/icons-material/Hub';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import NewspaperIcon from '@mui/icons-material/Newspaper';
//LOGOS PNG:
import icone_makeforward from '../../assets/icone_makeforward.png';
import icone_maketheprice from '../../assets/icone_maketheprice.png';
import icone_makeview from '../../assets/icone_makeview_2.png';
//STYLING:
import './style.css';
import theme from '../../theme';

function index({ page }) {
  function ButtonLogo({ page }) {
    let imgContainer = {
      width: '100%',
      borderRadius: '5%'
    };
    let standardImg = {
      width: '100%',
      borderRadius: '5%'
    };
    if (page === 'make-the-price') {
      return (
        <div style={imgContainer}>
          <img style={standardImg} src={icone_maketheprice}></img>
        </div>
      );
    } else if (page === 'make-forward') {
      return (
        <div style={imgContainer}>
          <img style={standardImg} src={icone_makeforward}></img>
        </div>
      );
    } else if (page === 'tag-management') {
      return <LabelIcon sx={{ color: '#FFF', width: '60px' }} />;
    } else if (page === 'integrations-management') {
      return <HubIcon sx={{ color: '#FFF', width: '60px' }} />;
    } else if (page.includes('upload')) {
      return <CloudUploadIcon sx={{ color: '#FFF', width: '60px' }} />;
    } else if (page === 'solicitar') {
      return <NoteAddIcon sx={{ color: '#FFF', width: '17px' }} />;
    } else if (page === 'categorias') {
      return <NoteAddIcon sx={{ color: '#FFF', width: '17px' }} />;
    } else if (page === 'make-view') {
      return (
        <div style={imgContainer}>
          <img style={standardImg} src={icone_makeview}></img>
        </div>
      );
    } else if (page === 'make-view-files') {
      return <SnippetFolderIcon sx={{ color: '#FFF', width: '17px' }} />;
    } else if (page === 'make-view-dashboards') {
      return <LineAxisIcon sx={{ color: '#FFF', width: '15px' }} />;
    } else if (page === 'reports') {
      return <SummarizeOutlinedIcon sx={{ color: '#FFF', fontSize: '15px' }} />;
    } else if (page === 'tax-information') {
      return <NewspaperIcon sx={{ color: '#FFF', fontSize: '18px' }} />;
    } else {
      return <NoteAddIcon sx={{ color: '#FFF', width: '17px' }} />;
    }
  }

  const box = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background:
      page === 'make-view'
        ? 'none'
        : page === 'make-forward'
        ? 'none'
        : page === 'tag-management'
        ? '#F27405'
        : page === 'integrations-management'
        ? '#F27405'
        : page === 'make-the-price'
        ? 'none'
        : page === 'upload'
        ? '#A646DC'
        : page === 'make-view-files'
        ? '#A646DC'
        : theme.palette.primary.main,
    height: '28px',
    width: '28px',
    borderRadius: '5px'
  };

  return (
    <>
      <div style={box}>
        <ButtonLogo page={page} />
      </div>
    </>
  );
}

export default index;
