import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ApiPostLegalBase,
  ApiGetNCMList,
  ApiGetNCMInfo,
  ApiTaxInformation,
  ApiTaxInformationSP
} from './baseLegal.api';

export const postLegalBase = createAsyncThunk(
  'api/postlegalbase',
  async ({
    initial_date,
    final_date,
    cnpj,
    nat_oper,
    market,
    estab_rem,
    tributation
  }) => {
    let response = await ApiPostLegalBase({
      initial_date,
      final_date,
      cnpj,
      nat_oper,
      market,
      estab_rem,
      tributation
    });
    return response;
  }
);

export const getNCMList = createAsyncThunk('api/basencmlist', async () => {
  let response = await ApiGetNCMList();
  return response;
});

export const getNCMInfo = createAsyncThunk(
  'api/basencminfo',
  async ({ cnpj, ncm }) => {
    let response = await ApiGetNCMInfo({
      cnpj,
      ncm
    });
    return response;
  }
);

// Tax Information Page
export const postTaxInformation = createAsyncThunk(
  'api/postTaxInformation',
  async ({ days, org, org_subs, ato, filtero_tributos, filtero_keyword }) => {
    let response = await ApiTaxInformation({
      days,
      org,
      org_subs,
      ato,
      filtero_tributos,
      filtero_keyword
    });
    return response;
  }
);

export const postTaxInformationSP = createAsyncThunk(
  'api/postTaxInformationSP',
  async ({ days, caderno, secao }) => {
    let response = await ApiTaxInformationSP({
      days,
      caderno,
      secao
    });
    return response;
  }
);

//export const notaAvulsaDataSlice
//name: 'notaavulsa'
//export const notaAvulsaSelector = (state) => state.notaAvulsaData
export const legalBaseDataSlice = createSlice({
  name: 'legalbase',
  initialState: {
    isPostLegalBase: false,
    legalBaseBody: {},
    isLoadingNCMList: false,
    ncmList: {},
    isLoadingNCMInfo: false,
    ncmInfo: {},
    isPostTaxInformation: false,
    taxInformationBody: {},
    isPostTaxInformationSP: false,
    taxInformationBodySP: {}
  },
  reducers: {},
  extraReducers: {
    [postLegalBase.pending]: (state) => {
      state.isPostLegalBase = 'loading';
    },
    [postLegalBase.fulfilled]: (state, action) => {
      state.isPostLegalBase = 'success';
      state.legalBaseBody = action.payload;
    },
    [postLegalBase.rejected]: (state) => {
      state.isPostLegalBase = 'failed';
    },
    [getNCMList.pending]: (state) => {
      state.isLoadingNCMList = 'loading';
    },
    [getNCMList.fulfilled]: (state, action) => {
      state.isLoadingNCMList = 'success';
      state.ncmList = action.payload;
    },
    [getNCMList.rejected]: (state) => {
      state.isLoadingNCMList = 'failed';
    },
    [getNCMInfo.pending]: (state) => {
      state.isLoadingNCMInfo = 'loading';
    },
    [getNCMInfo.fulfilled]: (state, action) => {
      state.isLoadingNCMInfo = 'success';
      state.ncmInfo = action.payload;
    },
    [getNCMInfo.rejected]: (state) => {
      state.isLoadingNCMInfo = 'failed';
    },
    [postTaxInformation.pending]: (state) => {
      state.isPostTaxInformation = 'loading';
    },
    [postTaxInformation.fulfilled]: (state, action) => {
      state.isPostTaxInformation = 'success';
      state.taxInformationBody = action.payload;
    },
    [postTaxInformation.rejected]: (state) => {
      state.isPostTaxInformation = 'failed';
    },
    [postTaxInformationSP.pending]: (state) => {
      state.isPostTaxInformationSP = 'loading';
    },
    [postTaxInformationSP.fulfilled]: (state, action) => {
      state.isPostTaxInformationSP = 'success';
      state.taxInformationBodySP = action.payload;
    },
    [postTaxInformationSP.rejected]: (state) => {
      state.isPostTaxInformationSP = 'failed';
    }
  }
});

export const legalBaseSelector = (state) => state.legalBaseData;

export default legalBaseDataSlice.reducer;
