import { configureStore } from '@reduxjs/toolkit';
import companyReducer from '../features/cadastroFeatures/Company/company.store';
import spedtypeReducer from '../features/elementarFeatures/sped/sped.store';
import QsDbListReducer from '../features/makeBiFeatures/quicksight/quicksightDbList.store';
import userDataReducer from '../features/userFeatures/userdata.store';
import fichaDataReducer from '../features/mtpFeatures/FichaFeatures/Ficha/ficha.store';
import clientListReducer from '../features/cadastroFeatures/Client/client.store';
import userListReducer from '../features/cadastroFeatures/Users/user.store';
//Um reducer da (arquitetura da) "Root Store" representa todos os reducers e slices de uma dada store
//O redux toolkit é uma das arquiteturas possíveis para o redux no react
//Nota Avulsa, SubidaDeDocumentos, SolicitarDocumentos, DefinirCategorias e MakeView:
import notaAvulsaReducer from '../features/makeForward/makeForward.store';
import subidaDeDocumentosReducer from '../features/SubidaDeDocumentos/subidaDeDocumentos.store';
import solicitarDocumentosReducer from '../features/SolicitarDocumentos/solicitarDocumentos.store';
import definirCategoriasReducer from '../features/DefinirCategorias/definirCategorias.store';
import makeViewReducer from '../features/MakeView/makeView.store';
import legalBaseDataReducer from '../features/baseLegal/baseLegal.store';

export const RootStore = configureStore({
  reducer: {
    company: companyReducer,
    spedtype: spedtypeReducer,
    QsDbList: QsDbListReducer,
    userData: userDataReducer,
    fichaData: fichaDataReducer,
    clientlist: clientListReducer,
    user: userListReducer,
    notaAvulsaData: notaAvulsaReducer,
    subidaDeDocumentosData: subidaDeDocumentosReducer,
    solicitarDocumentosData: solicitarDocumentosReducer,
    definirCategoriasData: definirCategoriasReducer,
    makeViewData: makeViewReducer,
    legalBaseData: legalBaseDataReducer
  }
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});
