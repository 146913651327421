import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ApiClientDashboard,
  ApiClientDashboardSave,
  ApiCompanyByClient,
  ApiDeleteCadastrar,
  ApiGetClient,
  ApiSaveClient,
  ApiSingleClient,
  ApiUpdateClient,
  ApiUpdateClientCompany,
  ApiGetUsersByCompany,
  ApiDeleteUserFromCompany,
  ApiAttachUserToCompany
} from './client.api';

export const getClientList = createAsyncThunk(
  'api/getclientlist',
  async ({ page, per_page }) => {
    let response = await ApiGetClient({ page, per_page });
    return response;
  }
);

export const getSingleClient = createAsyncThunk(
  'api/singleclient',
  async ({ id }) => {
    let response = await ApiSingleClient({
      id
    });
    return response;
  }
);

//======SAVE CLIENT========//
export const saveClient = createAsyncThunk(
  'api/saveclient',
  // eslint-disable-next-line no-unused-vars
  async (data) => {
    let response = await ApiSaveClient(data);
    let { status, message, payload = {} } = response;

    return { status, message, payload };
  }
);

//======UPDATE CLIENT========//
export const getUpdateClient = createAsyncThunk(
  'api/updateclient',
  // eslint-disable-next-line no-unused-vars
  async ({ data, id }) => {
    let response = await ApiUpdateClient({ data, id });
    let { status, message, payload = {} } = response;

    return { status, message, payload };
  }
);

//======= GET COMPANIES BY CLIENT =============//
export const getCompanyByClient = createAsyncThunk(
  'api/companiesbyclient',
  async ({ id, page, per_page }) => {
    let response = await ApiCompanyByClient({
      id,
      page,
      per_page
    });
    return response;
  }
);

//======UPDATE CLIENT COMPANY========//
export const getUpdateClientCompany = createAsyncThunk(
  'api/updateclientcompany',
  // eslint-disable-next-line no-unused-vars
  async ({ data, id }) => {
    let response = await ApiUpdateClientCompany({ data, id });
    let { status, message, payload = {} } = response;

    return { status, message, payload };
  }
);

export const deleteCadastrar = createAsyncThunk(
  'api/deletecadastrar',
  async ({ id }) => {
    let response = await ApiDeleteCadastrar({ id });
    return response;
  }
);

export const getCadastrarDashboard = createAsyncThunk(
  'api/clientcadastrardashboard',
  async ({ client_id }) => {
    let response = await ApiClientDashboard({
      client_id
    });
    return response;
  }
);

export const saveClientDashboard = createAsyncThunk(
  'api/savedashboardclient',
  // eslint-disable-next-line no-unused-vars
  async (data) => {
    let response = await ApiClientDashboardSave(data);
    let { status, message, payload = {} } = response;

    return { status, message, payload };
  }
);

//--------------LIST USERS IN A COMPANY--------------
export const getUsersByCompany = createAsyncThunk(
  'api/getusersbycompanyslist',
  async ({ id_usuario, id_empresa, id_cliente }) => {
    let response = await ApiGetUsersByCompany({
      id_usuario,
      id_empresa,
      id_cliente
    });
    return response;
  }
);

//--------------ATTACH USER TO A COMPANY--------------
export const attachUserToCompany = createAsyncThunk(
  'api/attachusertocompany',
  // eslint-disable-next-line no-unused-vars
  async (data) => {
    let response = await ApiAttachUserToCompany(data);
    let { status, message, payload = {} } = response;

    return { status, message, payload };
  }
);

/*
export const attachUserToCompany = createAsyncThunk(
  'api/attachusertocompany',
  async ({ id_empresa, id_usuario }) => {
    let response = await ApiAttachUserToCompany({
      id_empresa,
      id_usuario
    });
    return response;
  }
);
*/

//--------------DELETE USER FROM A COMPANY--------------
export const deleteUserFromCompany = createAsyncThunk(
  'api/deleteuserfromcompany',
  async ({ cuser, company }) => {
    let response = await ApiDeleteUserFromCompany({ cuser, company });
    return response;
  }
);

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    clients: [],
    isLoadingClient: false,
    saveclients: {},
    isSavingClient: false,
    singleclient: {},
    isLoadingSingleClient: false,
    updateclient: {},
    isDeletingCadastrar: false,
    isUpdatingClient: false,
    companiesbyclient: [],
    isLoadingCompaniesbyClient: false,
    isUpdatingClientCompany: false,
    cadastrardashboard: [],
    isGettingCadastrarDashboard: false,
    saveDashboard: {},
    isSavingDashboardClient: false,
    isGettingUsersByCompany: false,
    usersByCompany: [],
    isDeletingUserFromCompany: false,
    isSavingUserToCompany: false,
    attachUser: {}
  },
  reducers: {},
  extraReducers: {
    [attachUserToCompany.pending]: (state) => {
      state.isSavingUserToCompany = 'loading';
    },
    [attachUserToCompany.fulfilled]: (state, action) => {
      state.isSavingUserToCompany = 'success';
      state.attachUser = action.payload;
    },
    [attachUserToCompany.rejected]: (state) => {
      state.isSavingUserToCompany = 'failed';
    },
    [deleteUserFromCompany.pending]: (state) => {
      state.isDeletingUserFromCompany = 'loading';
    },
    [deleteUserFromCompany.fulfilled]: (state) => {
      state.isDeletingUserFromCompany = 'success';
    },
    [deleteUserFromCompany.rejected]: (state) => {
      state.isDeletingUserFromCompany = 'failed';
    },
    [getUsersByCompany.pending]: (state) => {
      state.isGettingUsersByCompany = 'loading';
    },
    [getUsersByCompany.fulfilled]: (state, action) => {
      state.isGettingUsersByCompany = 'success';
      state.usersByCompany = action.payload;
    },
    [getUsersByCompany.rejected]: (state) => {
      state.isGettingUsersByCompany = 'failed';
    },
    [getClientList.pending]: (state) => {
      state.isLoadingClient = 'loading';
    },
    [getClientList.fulfilled]: (state, action) => {
      state.isLoadingClient = 'success';
      state.clients = action.payload;
    },
    [getClientList.rejected]: (state) => {
      state.isLoadingClient = 'failed';
    },
    [saveClient.pending]: (state) => {
      state.isSavingClient = 'loading';
    },
    [saveClient.fulfilled]: (state, action) => {
      state.isSavingClient = 'success';
      state.saveclients = action.payload;
    },
    [saveClient.rejected]: (state) => {
      state.isSavingClient = 'failed';
    },
    [getSingleClient.pending]: (state) => {
      state.isLoadingSingleClient = 'loading';
    },
    [getSingleClient.fulfilled]: (state, action) => {
      state.isLoadingSingleClient = 'success';
      state.singleclient = action.payload;
    },
    [getSingleClient.rejected]: (state) => {
      state.isLoadingSingleClient = 'failed';
    },
    [getUpdateClient.pending]: (state) => {
      state.isUpdatingClient = 'loading';
    },
    [getUpdateClient.fulfilled]: (state, action) => {
      state.isUpdatingClient = 'success';
      state.updateclient = action.payload;
    },
    [getUpdateClient.rejected]: (state) => {
      state.isUpdatingClient = 'failed';
    },
    [getCompanyByClient.pending]: (state) => {
      state.isLoadingCompaniesbyClient = 'loading';
    },
    [getCompanyByClient.fulfilled]: (state, action) => {
      state.isLoadingCompaniesbyClient = 'success';
      state.companiesbyclient = action.payload;
    },
    [getCompanyByClient.rejected]: (state) => {
      state.isLoadingCompaniesbyClient = 'failed';
    },
    [getUpdateClientCompany.pending]: (state) => {
      state.isUpdatingClientCompany = 'loading';
    },
    [getUpdateClientCompany.fulfilled]: (state) => {
      state.isUpdatingClientCompany = 'success';
    },
    [getUpdateClientCompany.rejected]: (state) => {
      state.isUpdatingClientCompany = 'failed';
    },
    [deleteCadastrar.pending]: (state) => {
      state.isDeletingCadastrar = 'loading';
    },
    [deleteCadastrar.fulfilled]: (state) => {
      state.isDeletingCadastrar = 'success';
    },
    [deleteCadastrar.rejected]: (state) => {
      state.isDeletingCadastrar = 'failed';
    },
    [getCadastrarDashboard.pending]: (state) => {
      state.isGettingCadastrarDashboard = 'loading';
    },
    [getCadastrarDashboard.fulfilled]: (state, action) => {
      state.isGettingCadastrarDashboard = 'success';
      state.cadastrardashboard = action.payload;
    },
    [getCadastrarDashboard.rejected]: (state) => {
      state.isGettingCadastrarDashboard = 'failed';
    },
    [saveClientDashboard.pending]: (state) => {
      state.isSavingDashboardClient = 'loading';
    },
    [saveClientDashboard.fulfilled]: (state, action) => {
      state.isSavingDashboardClient = 'success';
      state.saveDashboard = action.payload;
    },
    [saveClientDashboard.rejected]: (state) => {
      state.isSavingDashboardClient = 'failed';
    }
  }
});

export const clientSelector = (state) => state.clientlist;
export default clientSlice.reducer;
