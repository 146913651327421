import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { ELEMENTAR_BUCKET_NAME } from 'apiRoutes';
import { useDispatch, useSelector } from 'react-redux';
import {
  retrieveQsDb,
  getQsDbList,
  QsDbListSelector
} from 'features/makeBiFeatures/quicksight/quicksightDbList.store';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import LoadingTable from 'components/MTWFeedback/LoadingTable/index.js';
import TableExcelS3 from './TableExcelS3.js';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
//COMPONENTS
//AWS
import { Storage } from 'aws-amplify';
//LIBS
import ExcelJS from 'exceljs';
//STYLE
import theme from '../../../theme/index.js';
import '../pages/style.css';

const QuickSightEmbedding = require('amazon-quicksight-embedding-sdk');

const EmbedDashboard = ({ cnpj, sessionCnpjs }) => {
  const { DBList, isDBListDone, dashboard, isFetching } =
    useSelector(QsDbListSelector);
  /*Objetos das apis: 
  DBList: um array de objetos* que traduzem as informações dos dashboards do Quicksight da AWS
  isDBListDone: ...
  dashboard: a url de um dashboard específico
  isFetching: ...*/
  const [dbValue, setDbValue] = useState();
  /*dbValue: que reúne informações de um único dashboard (dashboard em uso)*/
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [s3TableAvailable, setS3TableAvailable] = useState(false);
  /*Booleano que controla se a tabela excel do S3 está disponível*/
  const [selectedButton, setSelectedButton] = useState(10001);
  /*Opção escolhida dentre as opções de dashboards no menu principal da página*/
  const [loading, setLoading] = useState(true);
  /*Controla o loading de carregamento*/
  const [excelFileLink, setExcelFileLink] = useState([]);
  const [excelFileData, setExcelFileData] = useState([]);

  //--------------------API--------------------
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => setLoading(false), 15000);
  }, []);

  useEffect(() => {
    dispatch(getQsDbList());
  }, []);
  /*Chama o DBList*/

  useEffect(() => {
    if (DBList && DBList.items && DBList.items.length) {
      setDbValue(DBList.items[0]);
    }
  }, [DBList]);
  /*Define o primeiro state do dbValue*/

  useEffect(() => {
    if (cnpj?.length > 0) {
      setSelectedButton(10001);
      setDbValue();
      setExcelFileData([]);
      const containerDiv = document.getElementById('dashboardContainer');
      while (containerDiv.firstChild) {
        containerDiv.removeChild(containerDiv.firstChild);
      }
    }
  }, [cnpj]);

  //--------------------Embedding do Quicksight--------------------
  useEffect(() => {
    if (dbValue) {
      let dashboardid = dbValue?.Url;
      if (dashboardid && sessionCnpjs) {
        let body = {
          dashboardid: dashboardid,
          cnpjs: sessionCnpjs
        };
        dispatch(retrieveQsDb(body));
      }
    }
  }, [dbValue, sessionCnpjs]);
  /*Com a useState dbValue setada (dashboard selecionado), 
  a url de um dashboard Quicksight real é chamado*/

  const onDashboardLoad = () => {
    setTimeout(() => setLoading(false), 120);
  };
  /*Termina a animação de carregamento através da função nativa do AWS Quicksight*/

  const getUrl = () => {
    const containerDiv = document.getElementById('dashboardContainer');
    while (containerDiv.firstChild) {
      containerDiv.removeChild(containerDiv.firstChild);
    }
    const dashboardOptions = {
      url: dashboard.url,
      dashboardId: {
        Id: dbValue?.Url
      },
      container: containerDiv,
      parameters: {
        country: 'United States'
      },
      scrolling: isMobile ? 'yes' : 'no',
      height: '800px',
      undoRedoDisabled: true,
      resetDisabled: true,
      footerPaddingEnabled: true
    };
    try {
      // eslint-disable-next-line no-unused-vars
      const dashboardWrapper =
        QuickSightEmbedding.embedDashboard(dashboardOptions);
      dashboardWrapper.on('load', onDashboardLoad);
    } catch (error) {
      console.error('Erro no embedding do dashboard:', error);
    }
  };
  /*Auxiliary function that builds up the embed element*/

  useEffect(() => {
    if (dbValue && dashboard?.url?.length > 0) {
      console.log('useEffect disparador do getUrl, eis o dbValue:', dbValue);
      if (dbValue !== 'tabelas3') {
        getUrl();
      }
    }
  }, [dashboard, dbValue]);
  /*dashboard (objeto api dashboard) dispara a getUrl*/

  //--------------------Loading de carregamento do(s) dashboard(s)--------------------
  useEffect(() => {
    if (
      (typeof selectedButton === 'number' && selectedButton === 0) ||
      selectedButton
    ) {
      if (isFetching) {
        console.log('Iniciador do loading');
        setLoading(true);
      }
    }
  }, [selectedButton, isFetching]);
  /*Inicia ou reinicia a animação de carregamento*/

  useEffect(() => {
    if (selectedButton === 10000 && dbValue === 'tabelas3') {
      setLoading(false);
    }
  }, [selectedButton]);
  /*Termina a animação de carregamento*/

  useEffect(() => {
    if ((DBList?.items?.length === 0 || DBList.length === 0) && isDBListDone) {
      setLoading(false);
    }
  }, [DBList, isDBListDone]);
  /*Termina a animação de carregamento*/

  const dbListItems = DBList?.items;
  /*dbListItems é a desestruturação do DBList*/

  //--------------------Menu horizontal de dashboards--------------------
  const handleDBValue = (chosenDashboard, index) => {
    setDbValue(chosenDashboard);
    setSelectedButton(index);
  };
  /*Define o dashboard que será carregado e define o menu que ficará sublinhado*/

  //--------------------Fetch da tabela Excel do S3--------------------
  const retrieveS3File = async (cnpj) => {
    console.log('retrieveS3File com o cnpj:', cnpj);
    try {
      const url = await Storage.get(`sumarios/${cnpj}-summary.xlsx`, {
        bucket: `${ELEMENTAR_BUCKET_NAME}`,
        region: 'us-east-1',
        contentType:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      setExcelFileLink(url);
    } catch (error) {
      console.error('Error retrieving file:', error);
    }
  };
  /**/

  async function fetchS3FileData(link) {
    try {
      const response = await fetch(link);
      const data = await response.arrayBuffer(); //use response.blob() or other methods depending on the file type
      if (response.statusText === 'Not Found' && dbListItems) {
        handleDBValue(dbListItems[0], 0);
        setS3TableAvailable(false);
      } else if (response.statusText === 'Ok' || response.statusText === 'OK') {
        setS3TableAvailable(true);
      }
      processExcelData(data);
    } catch (error) {
      console.error('Error fetching S3 file data:', error);
    }
  }
  /**/

  const processExcelData = (data) => {
    const workbook = new ExcelJS.Workbook();
    workbook.xlsx
      .load(data)
      .then(() => {
        const worksheet = workbook.worksheets[0];
        const rows = [];

        worksheet.eachRow((row) => {
          const rowData = [];
          row.eachCell((cell) => {
            rowData.push(cell.text);
          });
          rows.push(rowData);
        });

        setExcelFileData(rows);
      })
      .catch((error) => {
        console.error('Error processing Excel data:', error);
      });
  };
  /**/

  //--------------------Mounting da tabela do S3--------------------
  useEffect(() => {
    if (cnpj?.length) {
      retrieveS3File(cnpj);
    }
  }, [cnpj]);
  /**/

  useEffect(() => {
    console.log('excelFileLink...:', excelFileLink);
    if (excelFileLink?.length) {
      fetchS3FileData(excelFileLink);
    }
  }, [excelFileLink]);
  /**/

  //--------------------JSX--------------------
  const embedDashboardContent = {
    position: 'relative'
  };

  const loadingOverflow = {
    position: 'absolute',
    top: '100px',
    zIndex: '5000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%'
  };

  const s3TableScrollLeft = () => {
    document.getElementById('s3TableContainer').scrollLeft -= 200;
  };

  const s3TableScrollRight = () => {
    document.getElementById('s3TableContainer').scrollLeft += 200;
  };

  function EmptySection({ object }) {
    const container = {
      marginTop: '60px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    };
    const iconWrapper = {
      textAlign: 'center',
      width: '100%',
      height: 'fit-content',
      padding: '2px 0px 2px 0px',
      marginBottom: '4px'
    };
    const icon = {
      fontSize: '72px',
      color: '#DDDDDD'
    };
    const firstLine = {
      textAlign: 'center',
      width: '100%',
      height: 'fit-content',
      padding: '2px 0px 2px 0px',
      marginBottom: 0,
      fontSize: '20px',
      fontWeight: '600',
      color: '#5D5E66'
    };
    const secondLine = {
      textAlign: 'center',
      width: '100%',
      height: 'fit-content',
      padding: '2px 0px 2px 0px',
      marginBottom: 0,
      fontSize: '16px',
      color: '#B9B9B9'
    };
    return (
      <div style={container}>
        {object === 'empty' ? (
          <>
            <div style={iconWrapper}>
              <QueryStatsIcon sx={icon} />
            </div>
            <div style={firstLine}>Nenhum painel disponível</div>
            <div style={secondLine}>
              <div>Você ainda não tem dados para gerar painéis</div>
            </div>
          </>
        ) : object === 'not-empty' ? (
          <>
            <div style={iconWrapper}>
              <PanToolAltIcon sx={icon} />
            </div>
            <div style={firstLine}>Selecione uma opção disponível</div>
            <div style={secondLine}></div>
          </>
        ) : (
          <div></div>
        )}
      </div>
    );
  }

  function TableExcelS3Section({ children }) {
    const container = {
      width: '100%',
      fontSize: '14px',
      color: '#5D5E66',
      overflowY: 'auto',
      overflowX: 'auto'
    };
    const fixedScrollContainer = {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative'
    };
    const fixedScroll = {
      display: 'flex',
      justifyContent: 'space-around',
      columnGap: '3px',
      padding: '3px',
      position: 'fixed',
      bottom: '30px',
      width: '324px',
      borderRadius: '6px'
    };
    const fixedScrollButton = {
      border: 'none',
      flex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '6px',
      width: 'fit-content',
      height: '36px',
      fontSize: '16px'
    };
    return (
      <div style={container} id={'s3TableContainer'}>
        <div style={fixedScrollContainer}>
          <div style={fixedScroll} className={'makeBIRule'}>
            <button
              style={fixedScrollButton}
              onClick={s3TableScrollLeft}
              className={'makeBIRuleButton'}
            >
              <ArrowLeftIcon />
            </button>
            <button
              style={fixedScrollButton}
              onClick={s3TableScrollRight}
              className={'makeBIRuleButton'}
            >
              <ArrowRightIcon />
            </button>
          </div>
        </div>
        <div>{children}</div>
      </div>
    );
  }

  return (
    <>
      {/*tabs menu*/}
      <div className="button-linear-group-container">
        {dbListItems && (
          <div className="button-linear-group">
            {/*1.1 summary excel table option*/}
            {s3TableAvailable && (
              <div className="button-linear-wrap" key={10000}>
                <button
                  key={10000}
                  className={`button-linear ${
                    selectedButton === 10000 ? 'selected' : ''
                  }`}
                  onClick={() => handleDBValue('tabelas3', 10000)}
                >
                  Sumário
                </button>
              </div>
            )}
            {/*1.2 dashboard options list*/}
            {dbListItems &&
              dbListItems.map((dashboard, i) => (
                <div className="button-linear-wrap" key={i}>
                  <button
                    key={i}
                    className={`button-linear ${
                      selectedButton === i ? 'selected' : ''
                    }`}
                    onClick={() => handleDBValue(dashboard, i)}
                  >
                    {dashboard.Dashboard}
                  </button>
                  <div
                    className={
                      selectedButton === i
                        ? 'button-line-indicator-selected'
                        : 'button-line-indicator'
                    }
                  ></div>
                </div>
              ))}
          </div>
        )}
        <hr className={`button-line`} />
      </div>
      {/*loading / empty section / summary / dashboards*/}
      <div style={embedDashboardContent}>
        {loading ? (
          <div style={loadingOverflow}>
            <LoadingTable
              color={'standard'}
              height={'80px'}
              border={'none'}
              boxed={true}
              background={'none'}
            />
          </div>
        ) : isDBListDone && s3TableAvailable && selectedButton === 10000 ? (
          <TableExcelS3Section>
            {excelFileData?.length > 0 && <TableExcelS3 data={excelFileData} />}
          </TableExcelS3Section>
        ) : isDBListDone &&
          (dbListItems?.length !== 0 || excelFileData?.length !== 0) &&
          !(selectedButton <= 99) &&
          selectedButton !== 10000 ? (
          <EmptySection object={'not-empty'} />
        ) : isDBListDone &&
          dbListItems?.length === 0 &&
          excelFileData?.length === 0 ? (
          <EmptySection object={'empty'} />
        ) : null}
        <div
          style={{
            display: 'block',
            visibility:
              selectedButton === 10000 || selectedButton === 10001
                ? 'hidden'
                : 'visible',
            height:
              selectedButton === 10000 || selectedButton === 10001
                ? '150px'
                : 'auto',
            overflowY: 'auto'
          }}
        >
          <div id="dashboardContainer"></div>
        </div>
      </div>
    </>
  );
};

export default EmbedDashboard;
