import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import ComboBox from '../../../components/MTWDataDisplay/ComboBox';
import { useDispatch, useSelector } from 'react-redux';
import {
  QsDbListSelector,
  getQsTopicList,
  getQsTopicSearch
} from 'features/makeBiFeatures/quicksight/quicksightDbList.store';

let QuickSightEmbedding = require('amazon-quicksight-embedding-sdk');

const EmbedQSearchBar = () => {
  const { topics, topicframe } = useSelector(QsDbListSelector);
  const [topicValue, setTopicValue] = useState(topics[0]);

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQsTopicList());
  }, []);

  function onError() {
    console.log('Do something when the session fails loading');
  }

  function onOpen() {
    console.log('Do something when the Q search bar opens');
  }

  function onClose() {
    console.log('Do something when the Q search bar closes');
  }

  const getTopicUrl = async () => {
    let topicWrapper;
    const containerDiv = document.getElementById('topicContainer');
    containerDiv.innerHTML = '';
    const QSTopicOptions = {
      url: topicframe.url_q ? topicframe.url_q : 'default_url',
      container: containerDiv,
      //   width: '1000px',
      locale: 'pt-BR',
      qSearchBarOptions: {
        expandCallback: onOpen,
        collapseCallback: onClose,
        iconDisabled: false,
        topicNameDisabled: false,
        themeId: 'theme12345',
        allowTopicSelection: true
      }
    };
    // eslint-disable-next-line no-unused-vars
    if (QSTopicOptions?.url?.length > 12) {
      topicWrapper = QuickSightEmbedding.embedQSearchBar(QSTopicOptions);
      topicWrapper.on('error', onError);
    }
  };

  let data = topics?.items;
  const firstRender = useRef();

  useEffect(() => {
    if (firstRender.current) {
      getTopicUrl();
    }
    firstRender.current = true;
  }, [topicframe.url_q]);

  useEffect(() => {
    let topicid = topicValue?.Url;
    if (topicid) {
      dispatch(getQsTopicSearch({ topicid }));
    }
  }, [topicValue]);

  const handleTopicValue = (e, newValue) => {
    setTopicValue(newValue);
  };

  const comboWidth = {
    width: '40%'
  };

  const flexDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 10
  };

  const animeStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '80px'
  };

  return (
    <section>
      <div style={flexDiv}>
        <div style={comboWidth}>
          <ComboBox
            options={data}
            value={topicValue}
            optionsLabel={(option) => option.Topico}
            setValue={handleTopicValue}
            label={
              data && data?.length ? data[0].Topico : 'No Options Available'
            }
            defaultValue={data && data.find((f) => f.Topico)}
            isOptionEqualToValue={(option, value) => {
              return option?.Url === value?.Url;
            }}
          />
        </div>
      </div>

      {data?.length === 0 ? (
        <Typography sx={animeStyle}>No Topic Available</Typography>
      ) : (
        <div id="topicContainer"></div>
      )}
    </section>
  );
};

export default EmbedQSearchBar;
