import React from 'react';
import FetchingAnimation from 'components/MTWFeedback/Fetching';
import theme from 'theme';

function index({ color, height, border, background, boxed, bottomOnly }) {
  const colorTranslation = (color) => {
    if (color === 'standard') {
      return `${theme.palette.primary.main}`;
    } else {
      return `${color}`;
    }
  };

  const boxedDiv = {
    background: boxed ? '#FFFFFFB3' : 'none',
    padding: boxed ? '1px' : 'none',
    borderRadius: boxed ? '6px' : 'none'
  };

  return (
    <div
      style={{
        border: bottomOnly
          ? 'none'
          : border
          ? border
          : `1px solid ${theme.palette.divisory.border}`,
        borderBottom:
          border === 'none'
            ? 'none'
            : `1px solid ${theme.palette.divisory.border}`,
        background: background ? background : theme.palette.background.standard,
        borderTop: 'none',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '0px 0px 5px 5px',
        height: height ? height : '200px'
      }}
    >
      <div style={boxedDiv}>
        <FetchingAnimation color={colorTranslation(color)} />
      </div>
    </div>
  );
}

export default index;
