import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ApiGetPerfilList,
  ApiGetUserList,
  ApiGetUsername,
  ApiSaveUser,
  ApiUpdateUser,
  ApiDeleteUserFromClient,
  //Dashboards
  ApiGetClientDashboards,
  ApiGetDashboardOptions,
  ApiCreateClientDashboard,
  ApiGetUserDashboards,
  ApiCreateUserDashboard
} from './user.api';
import { createAction } from '@reduxjs/toolkit';

export const getUpdateUser = createAsyncThunk(
  'api/updateuser',
  async ({ data, id }) => {
    let response = await ApiUpdateUser({ data, id });
    let { status, message, payload = {} } = response;
    return { status, message, payload };
  }
);

export const deleteUserFromClient = createAsyncThunk(
  'api/deleteuserfromclient',
  async ({ id }) => {
    let response = await ApiDeleteUserFromClient({ id });
    return response;
  }
);

export const getUserList = createAsyncThunk(
  'api/userlist',
  async ({ client_id, page, per_page }) => {
    let response = await ApiGetUserList({ client_id, page, per_page });
    return response;
  }
);

export const getPerfilList = createAsyncThunk('api/perfillist', async () => {
  let response = await ApiGetPerfilList();
  return response;
});

export const getUsername = createAsyncThunk(
  'api/username',
  async ({ email }) => {
    let response = await ApiGetUsername({ email });
    return response;
  }
);

//======SAVE USER========//

export const saveUser = createAsyncThunk(
  'api/saveuser',
  // eslint-disable-next-line no-unused-vars
  async (data) => {
    let response = await ApiSaveUser(data);
    let { status, message, payload = {} } = response;

    return { status, message, payload };
  }
);

//======DASHBOARDS======//

export const recordCurrentUserId = createAction(
  'notaavulsa/recordcurrentuserid',
  (data) => ({
    payload: data
  })
);

export const getClientDashboards = createAsyncThunk(
  'api/clientdashboards',
  async ({ client }) => {
    let response = await ApiGetClientDashboards({ client });
    return response;
  }
);

export const getDashboardOptions = createAsyncThunk(
  'api/dashboardoptions',
  async () => {
    let response = await ApiGetDashboardOptions();
    return response;
  }
);

export const createClientDashboard = createAsyncThunk(
  'api/createclientdashboard',
  async (data) => {
    let response = await ApiCreateClientDashboard(data);
    return response;
  }
);

export const getUserDashboards = createAsyncThunk(
  'api/userdashboards',
  async ({ client }) => {
    let response = await ApiGetUserDashboards({ client });
    return response;
  }
);

export const createUserDashboard = createAsyncThunk(
  'api/createuserdashboard',
  async (data) => {
    let response = await ApiCreateUserDashboard(data);
    return response;
  }
);

export const UserSlice = createSlice({
  name: 'userslice',
  initialState: {
    userlist: [],
    isGettingUserList: false,
    perfillist: [],
    username: {},
    isGettingUsername: false,
    isGettingPerfilList: false,
    saveuser: {},
    isSavingUser: false,
    isUpdatingUser: false,
    isDeletingUserFromClient: false,
    //Dashboards:
    isGettingClientDashboards: false,
    clientDashboards: {},
    isGettingDashboardOptions: false,
    dashboardOptions: {},
    isCreatingClientDashboard: false,
    createClientDashboard: {},
    isGettingUserDashboards: false,
    userDashboards: {},
    isCreatingUserDashboard: false,
    userDashboardCreated: {},
    currentUserId: {}
  },
  reducers: {},
  extraReducers: {
    [recordCurrentUserId]: (state, action) => {
      state.currentUserId = action.payload;
    },
    [getUpdateUser.pending]: (state) => {
      state.isUpdatingUser = 'loading';
    },
    [getUpdateUser.fulfilled]: (state) => {
      state.isUpdatingUser = 'success';
    },
    [getUpdateUser.rejected]: (state) => {
      state.isUpdatingUser = 'failed';
    },
    [deleteUserFromClient.pending]: (state) => {
      state.isDeletingUserFromClient = 'loading';
    },
    [deleteUserFromClient.fulfilled]: (state) => {
      state.isDeletingUserFromClient = 'success';
    },
    [deleteUserFromClient.rejected]: (state) => {
      state.isDeletingUserFromClient = 'failed';
    },
    [getUserList.pending]: (state) => {
      state.isGettingUserList = 'loading';
    },
    [getUserList.fulfilled]: (state, action) => {
      state.isGettingUserList = 'success';
      state.userlist = action.payload;
    },
    [getUserList.rejected]: (state) => {
      state.isGettingUserList = 'failed';
    },
    [getPerfilList.pending]: (state) => {
      state.isGettingPerfilList = 'loading';
    },
    [getPerfilList.fulfilled]: (state, action) => {
      state.isGettingPerfilList = 'success';
      state.perfillist = action.payload;
    },
    [getPerfilList.rejected]: (state) => {
      state.isGettingPerfilList = 'failed';
    },
    [getUsername.pending]: (state) => {
      state.isGettingUsername = 'loading';
    },
    [getUsername.fulfilled]: (state, action) => {
      state.isGettingUsername = 'success';
      state.username = action.payload;
    },
    [getUsername.rejected]: (state) => {
      state.isGettingUsername = 'failed';
    },
    [saveUser.pending]: (state) => {
      state.isSavingUser = 'loading';
    },
    [saveUser.fulfilled]: (state, action) => {
      state.isSavingUser = 'success';
      state.saveuser = action.payload;
    },
    [saveUser.rejected]: (state) => {
      state.isSavingUser = 'failed';
    },
    //Dashboards:
    [getClientDashboards.pending]: (state) => {
      state.isGettingClientDashboards = 'loading';
    },
    [getClientDashboards.fulfilled]: (state, action) => {
      state.isGettingClientDashboards = 'success';
      state.clientDashboards = action.payload;
    },
    [getClientDashboards.rejected]: (state) => {
      state.isGettingClientDashboards = 'failed';
    },
    //2
    [getDashboardOptions.pending]: (state) => {
      state.isGettingDashboardOptions = 'loading';
    },
    [getDashboardOptions.fulfilled]: (state, action) => {
      state.isGettingDashboardOptions = 'success';
      state.dashboardOptions = action.payload;
    },
    [getDashboardOptions.rejected]: (state) => {
      state.isGettingDashboardOptions = 'failed';
    },
    //3
    [createClientDashboard.pending]: (state) => {
      state.isCreatingClientDashboard = 'loading';
    },
    [createClientDashboard.fulfilled]: (state, action) => {
      state.isCreatingClientDashboard = 'success';
      state.createClientDashboard = action.payload;
    },
    [createClientDashboard.rejected]: (state) => {
      state.isCreatingClientDashboard = 'failed';
    },
    //4
    [getUserDashboards.pending]: (state) => {
      state.isGettingUserDashboards = 'loading';
    },
    [getUserDashboards.fulfilled]: (state, action) => {
      state.isGettingUserDashboards = 'success';
      state.userDashboards = action.payload;
    },
    [getUserDashboards.rejected]: (state) => {
      state.isGettingUserDashboards = 'failed';
    },
    //5
    [createUserDashboard.pending]: (state) => {
      state.isCreatingUserDashboard = 'loading';
    },
    [createUserDashboard.fulfilled]: (state, action) => {
      state.isCreatingUserDashboard = 'success';
      state.userDashboardCreated = action.payload;
    },
    [createUserDashboard.rejected]: (state) => {
      state.isCreatingUserDashboard = 'failed';
    }
  }
});

export const userSelector = (state) => state.user;
export default UserSlice.reducer;
