import { MTP_BASE_URL, ELEMENTAR_BASE_URL, DLM_BASE_URL } from 'apiRoutes';
import { http, handleErrors } from '../../services/http-wrapper';
import { Auth } from 'aws-amplify';

//TABELA:

export const ApiGetUploadedFiles = async ({
  id,
  type,
  page,
  per_page,
  error_message,
  data_inicio,
  data_fim,
  nota_type,
  file_name
}) => {
  let filterGroup = {
    id,
    type,
    page,
    per_page,
    error_message,
    data_inicio,
    data_fim,
    nota_type,
    file_name
  };
  let filters = Object.keys(filterGroup)
    .filter(
      (index) =>
        filterGroup[index] != '' &&
        filterGroup[index] != undefined &&
        filterGroup[index] != null
    )
    .reduce((a, index) => ({ ...a, [index]: filterGroup[index] }), {});
  //console.log('filters momento 1:', filters);
  if (filterGroup.page === 0 || filterGroup.page === '0') filters.page = 1;
  //console.log('filters momento 2:', filters);
  let filtersArray = Object.entries(filters);
  let queryArray = [];
  for (let i = 0; filtersArray.length > i; i++) {
    let field = filtersArray[i][0];
    let value = filtersArray[i][1];
    let queryString = `&${field}=${value}`;
    queryArray.push(queryString);
  }
  let query = queryArray.join('');
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/stored_files/list?${query}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//DEPRECATED
export const ApiGetUploadedFilesNoType = async ({ id, page, per_page }) => {
  if (page === 0) {
    page = 1;
  }
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/stored_files/list?id=${id}&page=${page}&per_page=${per_page}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiSaveUploadedFiles = async (saveData) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${MTP_BASE_URL}/stored_files/save`,
      saveData,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    console.log(error);
    // handlerErros(error);
    throw error;
  }
};

export const ApiDeleteFile = async (ids) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.voidPut(
      `${MTP_BASE_URL}/execution/delete`,
      ids,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    console.log(error);
    // handlerErros(error);
    throw error;
  }
};

export const ApiGetTypeOptions = async (id) => {
  let size = 100;
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/arquivouploadempresa/list_files_by_company?id_empresa=${id}&person=empresa&per_page=${size}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//EXECUÇÃO API
export const ApiCreateExecution = async (executionData) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${MTP_BASE_URL}/execution/create`,
      executionData,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const ApiGetExecution = async (id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/execution/read?id_empresa=${id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

// DLM (10)
export const ApiPostDocumentType = async ({
  company_id,
  extensao,
  nome,
  sigla
}) => {
  let data = {
    company_id: company_id,
    extensao: extensao,
    nome: nome,
    sigla: sigla
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${DLM_BASE_URL}/document/create/document_types`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetDocumentTypes = async (cnpj) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${DLM_BASE_URL}/document/list/document_types?cnpj=${cnpj}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiPostNewDocument = async ({
  key,
  document_type_id,
  descricao
}) => {
  let data = {
    key: key,
    document_type_id: document_type_id,
    descricao: descricao
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${DLM_BASE_URL}/document/create/document`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetDocumentsByType = async (document_type_id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${DLM_BASE_URL}/document/list/files?page=1&per_page=100&document_type_id=${document_type_id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiDeleteDLMDoc = async (id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${DLM_BASE_URL}/document/delete/document/${id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const ApiPostDLMTagDocument = async ({ document_id, tag_id }) => {
  let data = {
    document_id: document_id,
    tag_id: tag_id
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.voidPost(
      `${DLM_BASE_URL}/document/create/tag_document`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetDLMListKeyword = async ({ page, per_page }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${DLM_BASE_URL}/document/list/palavra_chave?page=${page}&per_page=${per_page}`,
      user.getIdToken().getJwtToken(),
      { page },
      { per_page }
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiPostDLMKeyword = async () => {
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${DLM_BASE_URL}/document/create/palavra_chave`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiPostDLMKeywordDocument = async () => {
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${DLM_BASE_URL}/document/create/palavra_chave_documento`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiPostDLMValidateTag = async ({
  tag_id,
  document_type_id,
  document_id,
  validation
}) => {
  let data = {
    tag_id: tag_id,
    document_type_id: document_type_id,
    document_id: document_id,
    validation: validation
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.dlmPost(
      `${DLM_BASE_URL}/document/validate_tag`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiDeleteDLMKeyword = async (id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${DLM_BASE_URL}/document/delete/palavra_chave/${id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const ApiPostDLMDocumentVersion = async ({
  key,
  latest_document_id,
  versao
}) => {
  let data = {
    key: key,
    latest_document_id: latest_document_id,
    versao: versao
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${DLM_BASE_URL}/document/create/document_version`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiPutDocumentApproval = async ({ document_id, status }) => {
  try {
    let data = {
      document_id: document_id,
      status: status
    };
    let user = await Auth.currentSession();
    const api = await http.put(
      `${DLM_BASE_URL}/document/validate/request`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

// DLM WORKFLOW (8)
export const ApiPostDLMTag = async ({
  name,
  color,
  company_id,
  document_type_id
}) => {
  let data = {
    name: name,
    color: color,
    company_id: company_id,
    document_type_id: document_type_id
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${DLM_BASE_URL}/work_flow/create/tag`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetDLMTags = async (document_type_id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${DLM_BASE_URL}/work_flow/list/tag?document_type_id=${document_type_id}&per_page=100&page=1`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiPostDLMWorkflow = async ({
  document_type_id,
  tag_orig_id,
  tag_dest_id,
  validation
}) => {
  let data = {
    document_type_id: document_type_id,
    tag_orig_id: tag_orig_id,
    tag_dest_id: tag_dest_id,
    validation: validation
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.dlmPost(
      `${DLM_BASE_URL}/work_flow/create/tag_workflow`,
      data,
      user.getIdToken().getJwtToken()
    );

    return api.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const ApiGetDLMWorkflow = async (document_type_id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${DLM_BASE_URL}/work_flow/list/tag_workflow?document_type_id=${document_type_id}&per_page=100&page=1`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiPostDLMTagWarning = async ({ tag_id, email, aviso }) => {
  let data = {
    tag_id: tag_id,
    email: email,
    aviso: aviso
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${DLM_BASE_URL}/work_flow/create/tag_aviso`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiGetDLMTagWarning = async (tag_id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${DLM_BASE_URL}/work_flow/list/tag_aviso?tag_id=${tag_id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiDeleteDLMTag = async (id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.voidRemove(
      `${DLM_BASE_URL}/work_flow/delete/tag/${id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const ApiDeleteDLMTagWorkflow = async (id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.voidRemove(
      `${DLM_BASE_URL}/work_flow/delete/tagworkflow/${id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const ApiPutDLMUpdateTagWorkflow = async ({
  id,
  id_tag_orig,
  id_tag_dest
}) => {
  let data = {
    id: id,
    id_tag_orig: id_tag_orig,
    id_tag_dest: id_tag_dest
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.dlmPut(
      `${DLM_BASE_URL}/work_flow/update/tag_work_flow`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

// W/ DLM_BASE_URL
export const AltApiGetListDocumentsByType = async (document_type_id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${DLM_BASE_URL}/document/list/files?document_type_id=${document_type_id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

//Anexos
export const ApiPostDLMAttachDocuments = async ({ id_documento, id_anexo }) => {
  try {
    let data = {
      id_documento,
      id_anexo
    };
    let user = await Auth.currentSession();
    const api = await http.post(
      `${DLM_BASE_URL}/document/create/documento_anexo`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const ApiDeleteDLMAttachDocuments = async (idAnexo) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.voidRemove(
      `${DLM_BASE_URL}/document/delete/documento_anexo/${idAnexo}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
