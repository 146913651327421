import React from 'react';
//COMPONENTS:
//import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import { useNavigate } from 'react-router-dom';
//STYLING:
import './style.css';

function index({ link }) {
  let navigate = useNavigate();

  return (
    <button className="roundButton" onClick={() => navigate(link ? link : '/')}>
      <ShortcutIcon
        sx={{ marginTop: '1px', width: '13px', transform: 'scaleX(-1)' }}
      />
    </button>
  );
}

export default index;
