import React from 'react';
//MUI:
import BusinessIcon from '@mui/icons-material/Business';
//COMPONENTS:
import MTWPageBadgeLite from 'components/PageBadgeLite';
import ButtonReturn from 'components/MTWActions/ButtonReturn';
//SERVICES:
//import { useNavigate } from 'react-router-dom';
//STYLING:
import './style.css';
import theme from '../../theme';

function MTWProductHeaderLite({
  page,
  pageBack,
  hidden,
  alert,
  pageTitle,
  cnpj,
  buttons,
  noCompany,
  //language,
  //toggleLanguage,
  children
}) {
  const minimumGap = {
    marginTop: '12px'
  };
  const beforeContainer = {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginTop: '8px',
    marginBottom: '2px',
    width: '100%'
  };
  const firstRowContainer = {
    marginTop: '0px',
    display: 'block',
    padding: '2px 6px 2px 0px',
    borderRadius: '5px',
    marginBottom: '14px'
  };
  /*Lado esquerdo:*/
  const leftSideWrapper = {
    flexWrap: 'nowrap',
    display: 'flex',
    alignItems: 'center'
  };
  const pageInformation = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '13px'
  };
  const pageTitleSection = {
    display: 'flex',
    alignItems: 'center'
  };
  const pageCompany = {
    marginTop: '3px',
    maxWidth: 'fit-content',
    maxHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '5px',
    border: alert ? '1px solid tomato' : '1px solid #C5C5C5',
    background: alert ? 'tomato' : 'none',
    color: alert ? '#FFFFFF' : '#999999',
    borderRadius: '5px',
    padding: '0px 5px 0px 5px',
    fontSize: '11px'
  };
  /*Lado direito:*/
  const rightSideWrapper = {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    columnGap: '14px',
    padding: buttons ? '24px 0px 0px 0px' : '0px 0px 0px 0px'
  };
  const titleRow = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  };
  const pageNavigation = {
    display: 'block'
  };
  /*Título:*/
  const ipageTitle = {
    padding: '0px 0px 2px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    color:
      page === 'make-forward'
        ? theme.palette.secondary[80]
        : page === 'upload'
        ? theme.palette.accentPurple.main
        : page === 'solicitar'
        ? theme.palette.accentPurple.main
        : page === 'categorias'
        ? theme.palette.accentPurple.main
        : page === 'make-view'
        ? theme.palette.accentPurple.main
        : theme.palette.primary.main,
    fontSize: '22px',
    fontWeight: '700',
    letterSpacing: '-0.20px'
  };

  //----------|----------JSX FN----------|----------
  /*
  function ToggleLanguage() {
    let hidden = { display: 'flex', visibility: 'hidden' };
    let show = { display: 'flex', visibility: 'visible' };
    return (
      <button
        onClick={() => toggleLanguage()}
        style={{
          backgroundColor: '#DCDCDC',
          height: '32px',
          width: '68px',
          color: 'white',
          fontSize: '16px',
          padding: '10px',
          position: 'relative',
          transition: 'background-color 0.5s ease',
          //zIndex: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: '2px solid #969696',
          borderRadius: '30px'
        }}
      >
        <div style={language === 'deutsch' ? show : hidden}>
          {DeutschFlag()}
        </div>
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '50%',
            height: '24px',
            width: '24px',
            position: 'absolute',
            right: language === 'deutsch' ? '4%' : 'calc(100% - 27px)',
            top: '50%',
            transform: 'translateY(-50%)',
            transition: 'right 0.5s ease',
            zIndex: 0
          }}
        ></div>
        <div style={language === 'deutsch' ? hidden : show}>
          {PortugueseFlag()}
        </div>
      </button>
    );
  }
  */

  /*
  const groupOfPagesList = {
    makeForward: [
      { name: 'NF-e', link: '/make-forward' },
      { name: 'NFS-e', link: '/make-forward-nfse' }
    ]
  };
  */

  /*
  const navigate = useNavigate();
  function groupOfPagesNavButton({ groupOfPages }) {
    const list = groupOfPagesList[groupOfPages];
    const pages = list.map((page) => page.name);
    const [selectedOption, setSelectedOption] = useState(currentPage);
    const [isOpen, setIsOpen] = useState(false);

    const handleButtonClick = (bool) => {
      setIsOpen(bool);
    };
    const handleOptionClick = (option) => {
      let link = list.find((page) => page.name === option)?.link;
      setSelectedOption(option);
      setIsOpen(false);
      let goLink = () => navigate(link);
      goLink();
    };
    const dropdownOptions = pages.filter((option) => option !== selectedOption);
    return (
      <div
        onMouseEnter={() => handleButtonClick(true)}
        onMouseLeave={() => handleButtonClick(false)}
        className="dropdown-sis"
      >
        <button>{selectedOption}</button>
        {isOpen && (
          <div className="dropdown-menu-sis">
            {dropdownOptions.map((option) => (
              <button key={option} onClick={() => handleOptionClick(option)}>
                {option}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  }
  */

  /*
  function DeutschFlag() {
    let style = { width: '18px' };
    return (
      <img
        src="https://flagicons.lipis.dev/flags/4x3/de.svg"
        alt="DE"
        style={style}
      />
    );
  }

  function PortugueseFlag() {
    let style = { width: '18px' };
    return (
      <img
        src="https://flagicons.lipis.dev/flags/4x3/pt.svg"
        alt="PT"
        style={style}
      />
    );
  }
  */

  return (
    <>
      {!hidden ? (
        <div>
          <div style={beforeContainer}>
            <ButtonReturn link={pageBack} />
          </div>
          <div id="first-row-container" style={firstRowContainer}>
            <div style={titleRow}>
              <div id="left-size">
                <div style={leftSideWrapper}>
                  <div style={pageNavigation}>
                    <MTWPageBadgeLite page={page} />
                  </div>

                  <div style={pageInformation}>
                    <div style={pageTitleSection}>
                      <div style={ipageTitle}>{pageTitle}</div>
                    </div>
                    {!noCompany && (
                      <div style={pageCompany}>
                        <BusinessIcon
                          sx={{
                            width: '12px'
                          }}
                        />
                        {cnpj}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div id="right-side" style={rightSideWrapper}>
                {children}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={minimumGap}></div>
      )}
    </>
  );
}

export default MTWProductHeaderLite;
