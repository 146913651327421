import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ApiChangeFichaStatus,
  ApiGetFichaStatus,
  ApiGetItemNotaList,
  ApiGetListaFicha,
  ApiGetProductFicha,
  ApiSaveFichaProduct,
  ApiDeleteFicha
} from './ficha.api';

export const getItemNota = createAsyncThunk('api/itemnota', async () => {
  let response = await ApiGetItemNotaList();
  return response;
});

export const saveFicha = createAsyncThunk('api/saveficha', async (data) => {
  let response = await ApiSaveFichaProduct(data);
  return response;
});

export const getProductFicha = createAsyncThunk(
  'api/productficha',
  async ({ idCliente, page, per_page }) => {
    let response = await ApiGetProductFicha({ idCliente, page, per_page });

    return response;
  }
);

export const getListaFicha = createAsyncThunk(
  'api/listaficha',
  async ({ idProductFicha, page, per_page }) => {
    let response = await ApiGetListaFicha({ idProductFicha, page, per_page });
    return response;
  }
);

export const getFichaStatus = createAsyncThunk(
  'api/fichastatus',
  async ({ idFichaBody }) => {
    let response = await ApiGetFichaStatus({ idFichaBody });
    return response;
  }
);

export const deleteFicha = createAsyncThunk(
  'api/deleteficha',
  async ({ Id_produto_ficha3 }) => {
    let response = await ApiDeleteFicha({ Id_produto_ficha3 });
    return response;
  }
);

export const changeFichaStatus = createAsyncThunk(
  'api/changefichastatus',
  async ({ IdProdutoFicha3 }) => {
    let response = await ApiChangeFichaStatus({ IdProdutoFicha3 });
    return response;
  }
);

export const fichaDataSlice = createSlice({
  name: 'ficha',
  initialState: {
    itemnota: [],
    productficha: [],
    isGetProductficha: false,
    isSaveFicha: false,
    isSaving: 'idle',
    isLoadingitemnota: false,
    listaficha: [],
    fichaBody: {},
    isGetListaFicha: false,
    fichaStatus: {},
    isGetFichaStatus: false,
    error: null,
    isDeleteFicha: {},
    isDeleting: false,
    changestatus: {},
    isChangeFichaStatus: false,
    isChangeStatus: false
  },
  reducers: {},
  extraReducers: {
    [getItemNota.pending]: (state) => {
      state.isLoadingitemnota = 'loading';
    },
    [getItemNota.fulfilled]: (state, action) => {
      state.isLoadingitemnota = 'success';
      state.itemnota = action.payload;
    },
    [getItemNota.rejected]: (state) => {
      state.isLoadingitemnota = 'failed';
    },
    [saveFicha.pending]: (state) => {
      state.isSaving = 'loading';
    },
    [saveFicha.fulfilled]: (state, action) => {
      state.isSaving = 'success';
      state.isSaveFicha = action.payload;
      state.fichaBody = action.payload;
    },
    [saveFicha.rejected]: (state) => {
      state.isSaving = 'failed';
      // console.log(action.error.message);
      // state.error = action.error?.message;
    },
    [getProductFicha.pending]: (state) => {
      state.isGetProductficha = 'loading';
    },
    [getProductFicha.fulfilled]: (state, action) => {
      state.isGetProductficha = 'success';
      state.productficha = action.payload;
    },
    [getProductFicha.rejected]: (state) => {
      state.isGetProductficha = 'failed';
    },
    [getListaFicha.pending]: (state) => {
      state.isGetListaficha = 'loading';
    },
    [getListaFicha.fulfilled]: (state, action) => {
      state.isGetListaficha = 'success';
      state.listaficha = action.payload;
    },
    [getListaFicha.rejected]: (state) => {
      state.isGetListaficha = 'failed';
    },
    [getFichaStatus.pending]: (state) => {
      state.isGetFichaStatus = 'loading';
    },
    [getFichaStatus.fulfilled]: (state, action) => {
      state.isGetFichaStatus = 'success';
      state.fichaStatus = action.payload;
    },
    [getFichaStatus.rejected]: (state) => {
      state.isGetFichaStatus = 'failed';
    },
    [deleteFicha.pending]: (state) => {
      state.isDeleting = 'loading';
    },
    [deleteFicha.fulfilled]: (state, action) => {
      state.isDeleting = 'success';
      state.isDeleteFicha = action.payload;
    },
    [changeFichaStatus.pending]: (state) => {
      state.isChangeFichaStatus = 'loading';
    },
    [changeFichaStatus.fulfilled]: (state, action) => {
      state.isChangeFichaStatus = 'success';
      state.changestatus = action.payload;
    },
    [changeFichaStatus.rejected]: (state) => {
      state.isChangeFichaStatus = 'failed';
    }
  }
});

export const fichadataSelector = (state) => state.fichaData;

export default fichaDataSlice.reducer;
