import React from 'react';
//import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import { RootStore } from './AppRoot/RootStore';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
//...
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

// eslint-disable-next-line
import '@aws-amplify/ui-react/styles.css'; // default theme

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={RootStore}>
        <App tab="home" />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
